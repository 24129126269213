import AlertaPush from '@/views/ADMIN/AlertaPush.vue'
import AlertaPushList from '@/components/AlertaPush/ListFilter.vue'
import AlertaPushAdd from '@/components/AlertaPush/Add.vue'

export const alertPushList = {
    path: "/alerta-push",
    name: "Push",
    component: AlertaPush,
    redirect: { name: "push-list" },
    children: [{
            path: "list",
            name: "push-list",
            component: AlertaPushList
        }, {
            path: "add",
            name: "push-add",
            component: AlertaPushAdd
        }, {
            path: "edit/:id",
            name: "push-edit",
            component: AlertaPushAdd
        },
    ],
};