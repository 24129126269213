import { render, staticRenderFns } from "./ColorSelection.vue?vue&type=template&id=0ac3aa50&scoped=true"
import script from "./ColorSelection.vue?vue&type=script&lang=js"
export * from "./ColorSelection.vue?vue&type=script&lang=js"
import style0 from "./ColorSelection.vue?vue&type=style&index=0&id=0ac3aa50&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac3aa50",
  null
  
)

export default component.exports