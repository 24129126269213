import { apolloClientCC as apolloClient } from "../../../vue-apollo";
import {
  SAVE_CHANNEL_LAYOUT,
  EDIT_CHANNEL_LAYOUT,
  DELETE_FILE_REPOSITORIO
} from "../../../apollo/mutations";
import {
  GET_ONE_CANAL,
  GET_PAGINAS_FILES_REPOSITORIO,
  GET_TOTAL_FILES_REPOSITORIO,
  GET_ALL_WIDGETS,
  GET_NOTICIAS_OPCIONES
} from "../../../apollo/queries";
import {
  SAVE_FILE_REPO
} from "../../../apollo/subscriptions";
import moment from 'moment';
import { VideoAdapter } from "../../../models/VideoAdapter";

function filteredRepoItems(items, pagination, page) {
  return items.filter(
    item => pagination[page].ids?.includes(item.id_archivo)
  ).reverse();
}
/*

function mergeRepoItems(ids1, ids2, limit) {
  const allIds = [...new Set([...ids1.flatMap(({ids})=>ids),...ids2.flatMap(({ids})=>ids)])]
  return allIds.reduce((acc, curr)=>{
    const len = acc.length; 
    if(len == 0){
      acc.push({pagina:"1",ids:[curr]})
      return acc
    }
    if(acc[len-1].ids.length < 12){
      acc[len-1].ids.push(curr)
    }else{
      acc.push({pagina:`${len+1}`,ids:[curr]})
  }
  return acc
},[])
}
*/

export default {
  namespaced: true,
  state: {
    channelLayout: {
      id_canal: "",
      id_layout: "",
      logo: {},
      url_logo: "",
      videos: [],
      tiempo_videos: "00:00:00",
      banners: [],
      bannersH: [],
      color: "",
      widgets: {},
      apiNews: [],
      timePerBanner: 4,
      timePerBannerH: 4
    },
    baseChannelLayout: {
      id_canal: "",
      id_layout: "",
      logo: {},
      url_logo: "",
      videos: [],
      tiempo_videos: "00:00:00",
      banners: [],
      bannersH: [],
      color: "",
      widgets: {},
      apiNews: [],
      timePerBanner: 4,  
      timePerBannerH: 4
    },
    selectedMediaList: [],
    imagesListTime:4,
    allRepoVideos: [],
    allRepoBanners: [],
    allRepoBannersH: [],
    allRepoImages: [],
    repoVideosPagination: [],
    repoBannersPagination: [],
    repoBannersHPagination: [],
    repoImagesPagination: [],
  },

  getters: {
    isLayoutValidForBack(state) {
      let valid = true;
      if (state.channelLayout.id_layout == 1 || state.channelLayout.id_layout == 2) {
        valid &= Object.keys(state.channelLayout.widgets).length >= 3;
        valid &= state.channelLayout.videos.length > 0;
        valid &= state.channelLayout.banners.length > 0;
        valid &= state.channelLayout.color !== "";
        valid &= state.channelLayout.url_logo !== "" || Object.keys(state.channelLayout.logo).length > 0;
        if (state.channelLayout.id_layout == 2) {
          valid &= state.channelLayout.apiNews.length > 0;
        }
      } else if (state.channelLayout.id_layout == 3) {
        valid &= Object.keys(state.channelLayout.widgets).length >= 1;
        valid &= state.channelLayout.videos.length > 0;
        valid &= state.channelLayout.color !== "";
        valid &= state.channelLayout.url_logo !== "" || Object.keys(state.channelLayout.logo).length > 0;
        valid &= state.channelLayout.apiNews.length !== 0;
      }
      return valid;
    },
    
    repoVideosFiltered: (state) => (page) =>  {
      return filteredRepoItems(state.allRepoVideos, state.repoVideosPagination, page)
    }
    ,
    repoBannersFiltered: (state) => (page) => {
      return filteredRepoItems(state.allRepoBanners, state.repoBannersPagination, page);
    },
    repoBannersHFiltered: (state) => (page) => {
      return filteredRepoItems(state.allRepoBannersH, state.repoBannersHPagination, page);
    },
    repoImagesFiltered: (state) => (page) =>  {
      return filteredRepoItems(state.allRepoImages, state.repoImagesPagination, page)
    },

    paginationDataForRepoVideos(state) {
      return {
        page_count: state.repoVideosPagination.length == 0 ? 
          1 : 
          state.repoVideosPagination.length
      };
    },
    paginationDataForRepoImages(state) {
      return {
        page_count: state.repoImagesPagination.length == 0 ? 
          1 : 
          state.repoImagesPagination.length
      };
    },
  },

  mutations: {
    setSelectedMediaList(state, media) {
      media.forEach(m => {
        state.selectedMediaList.push(m);
      })
    },
    removeSelectedMediaList(state, index) {
      state.selectedMediaList.splice(index, 1);
    },
    imagesListTimeUpdateState(state, time) {
      state.imagesListTime = time
    },
    resetChannelContent(state) {
      state.channelLayout = JSON.parse(JSON.stringify(state.baseChannelLayout));
    },
    setChannelLayoutLogo(state, logo) {
      state.channelLayout.logo = logo;
    },
    deleteChannelLayoutLogo(state) {
      state.channelLayout.logo = {};
      state.channelLayout.url_logo = "";
    },
    setChannelLayoutVideos(state, data_) {
      state.channelLayout.videos = data_.videos.slice();
      state.channelLayout.tiempo_videos = data_.tiempo_total;
    },
    setChannelLayoutBanners(state, data_) {
      state.channelLayout.banners = data_.banners.slice();
      state.channelLayout.timePerBanner = data_.timePerBanner;
    },
    setChannelLayoutBannersH(state, data_) {
      state.channelLayout.bannersH = data_.banners.slice();
      state.channelLayout.timePerBannerH = data_.timePerBanner;
    },
    setChannelLayoutColor(state, color) {
      state.channelLayout.color = color;
    },
    setChannelLayoutWidgets(state, data_) {
      if (state.channelLayout.widgets[data_.rank]) {
        Object.assign(state.channelLayout.widgets[data_.rank], data_.widget);
      } else {
        state.channelLayout.widgets[data_.rank] = { ...data_.widget };
      }
      state.channelLayout.widgets[data_.rank].tiempo_ejecucion = data_.tiempo_ejecucion;
    },
    setChannelLayoutApiNews(state, data_) {
      // push cuando sean múltiples apis:
      data_[0].id_widget = state.channelLayout.apiNews[0]?.id_widget ?? "0";
      state.channelLayout.apiNews = data_; 
    },
    setChannelLayoutId(state, id) {
      state.channelLayout.id_canal = id;
    },
    setChannelLayoutDesign(state, layout) {
      state.channelLayout.id_layout = layout.id;
    },
    getCanal(state, response) {
      state.channelLayout = JSON.parse(JSON.stringify(state.baseChannelLayout));

      const hms_to_sec = (hms) => { 
        let a = hms.split(':');
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
      };

      // Metadata
      state.channelLayout.id_canal  = response.id_canal;
      state.channelLayout.id_layout = response.lista_reproduccion[0].layout.id_layout;
      state.channelLayout.url_logo  = response.lista_reproduccion[0].url_logo;
      state.channelLayout.color     = response.lista_reproduccion[0].color;
      state.channelLayout.id_lista  = response.lista_reproduccion[0].id_lista;

      // Videos
      state.channelLayout.videos = response.lista_reproduccion[0].videos[0] ? 
        response.lista_reproduccion[0].videos
          .sort((a, b) => +a.orden - +b.orden)
          .map(video => VideoAdapter.from_get_canal(video)) : [];
      state.channelLayout.tiempo_videos = response.lista_reproduccion[0].videos[0] ?
        response.lista_reproduccion[0].videos[0].total_tiempo : "00:00:00";

      // Banners
      state.channelLayout.banners = response.lista_reproduccion[0].banners[0] ? 
        response.lista_reproduccion[0].banners
          .sort((a, b) => +a.orden - +b.orden)
          .map(banner => { return { id_file: banner.id_file, ...banner.archivo }}) : [];
      state.channelLayout.timePerBanner = response.lista_reproduccion[0].banners[0] ?
        hms_to_sec(response.lista_reproduccion[0].banners[0].tiempo_ejecucion) : 4;

      state.channelLayout.bannersH = response.lista_reproduccion[0].banners_horizontales[0] ? 
        response.lista_reproduccion[0].banners_horizontales
          .sort((a, b) => +a.orden - +b.orden)
          .map(banner => { return { id_file: banner.id_file, ...banner.archivo }}) : [];
      state.channelLayout.timePerBannerH = response.lista_reproduccion[0].banners_horizontales[0] ?
        hms_to_sec(response.lista_reproduccion[0].banners_horizontales[0].tiempo_ejecucion) : 4;


      // Widgets (tratamos widgets de noticias de distinta forma dada su posición en el layout)
      const frontWidgets = response.lista_reproduccion[0].widgets
      .filter(widget => !widget.api || widget.api.nombre_api !== "RRSS")
      .map(widget => ({
          id_widget: widget.id_widget,
          tipo_widget: widget.tipo_widget,
          tiempo_ejecucion: hms_to_sec(widget.tiempo_ejecucion),
          id_objeto:     widget.tipo_widget == "1" ? widget.modulo?.id_modulo     : widget.api?.id_api,
          imagen_widget: widget.tipo_widget == "1" ? widget.modulo?.url_imagen    : widget.api?.url_imagen,
          nombre_widget: widget.tipo_widget == "1" ? widget.modulo?.nombre_modulo : widget.api?.nombre_api,
          ubicaciones_clima: widget.api?.nombre_api == "Weather API"        ? widget.api.clima_api : [],  
          dias_accidente: widget.api?.id_api == 3 ? widget.api.dias_accidente.dias_accidente == null || widget.api.dias_accidente.dias_accidente == "" ? 
              "1" : widget.api.dias_accidente.dias_accidente : "0"
              // https://des-carpe.monday.com/boards/3896200137/pulses/5857145492 id_api de dias sin accidente es 3, validamos por aquí
        })
      );

      state.channelLayout.apiNews = response.lista_reproduccion[0].widgets_rrss[0] ? response.lista_reproduccion[0].widgets_rrss
        : [];

      // enumera en un obj los elementos de frontWidgets: { '0': widget0, '1':  ... }
      state.channelLayout.widgets = { ...frontWidgets }; 
    },
    getChannelStateFromLS(state) {
      if (localStorage.getItem('channelLayout')) {
        Object.assign(
          state.channelLayout, JSON.parse(localStorage.getItem('channelLayout'))
        );
      }
    },
    setAllRepoFiles(state, fileObjArr) {
      if (fileObjArr.length === 0) return;
      if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "1") {
        state.allRepoVideos = fileObjArr;
      } else if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "2") {
        state.allRepoBanners = fileObjArr;
      } else if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "3") {
        state.allRepoBannersH = fileObjArr;
      }else if (fileObjArr[0].detalle_tipoa.tipo_archivo.id_tipo === "4") {
        state.allRepoImages = fileObjArr;
      }
    },
    deleteRepoFile(state, resp) {
      const  {deleted, type} = resp
      if (type === "1") {
        state.allRepoVideos = state.allRepoVideos
          .filter(video => video.id_archivo != deleted.id_archivo);
      } else if (type === "2") {
        state.allRepoBanners = state.allRepoBanners
          .filter(banner => banner.id_archivo != deleted.id_archivo);
        state.allRepoBannersH = state.allRepoBannersH
          .filter(banner => banner.id_archivo != deleted.id_archivo);
      }else if (type === "3") {
        state.allRepoImages = state.allRepoImages
          .filter(img => img.id_archivo != deleted.id_archivo);
      }
    },
    /**
     * Setea la paginacion en el state segun el tipo de archivo
     * @param {Object} state - El state del modulo
     * @param {Object} { pagination, payload } - El objeto con la paginacion y el objeto con la informacion de la peticion
     * @param {Array} pagination - El arreglo de paginacion
     * @param {Object} payload - El objeto con la informacion de la peticion
     * @param {Number} payload.tipoArchivo - El tipo de archivo: 1: video, 2: banner, 5: bannerH, 6: imagen
     */
    setPagination(state, { pagination, payload }) {
      if (payload.tipoArchivo === "1") {
        state.repoVideosPagination = pagination;
      } 
      else if (payload.tipoArchivo === "6") {
        state.repoImagesPagination = pagination;
      } 
      else if (payload.tipoArchivo === "2") {
        state.repoBannersPagination = pagination;
      } 
      else if (payload.tipoArchivo === "5") {
        state.repoBannersHPagination = pagination;
      } 
    }
  },

  actions: {
    setSelectedMediaList({ commit }, mediaList) {
      commit("setSelectedMediaList", mediaList);
    },
    removeSelectedMediaList({ commit }, mediaList) {
      commit("removeSelectedMediaList", mediaList);

    },
    imagesListTimeUpdate({ commit }, time) {
      commit("imagesListTimeUpdateState", time);
    },
    getChannelStateFromLS({ commit }) {
      commit("getChannelStateFromLS");
    },
    resetChannelContent({ commit }) {
      commit("resetChannelContent");
    },
    setChannelLayoutLogo({ commit }, logo) {
      commit("setChannelLayoutLogo", logo);
    },
    deleteChannelLayoutLogo({ commit }) {
      commit("deleteChannelLayoutLogo");
    },
    setChannelLayoutVideos({ commit }, data_) {
      commit("setChannelLayoutVideos", data_);
    },
    setChannelLayoutBanners({ commit }, data_) {
      if (data_.isBannerH) {
        commit("setChannelLayoutBannersH", data_);
      } else {
        commit("setChannelLayoutBanners", data_);
      }
    },
    setChannelLayoutColor({ commit }, color) {
      commit("setChannelLayoutColor", color);
    },
    setChannelLayoutWidgets({ commit }, data_) {
      commit("setChannelLayoutWidgets", data_);
    },
    setChannelLayoutApiNews({ commit }, data_) {
      commit("setChannelLayoutApiNews", data_);
    },
    setChannelLayoutId({ commit }, id_canal) {
      commit("setChannelLayoutId", id_canal);
    },
    setChannelLayoutDesign({ commit }, layout) {
      commit("setChannelLayoutDesign", layout);
    },

    // eslint-disable-next-line no-unused-vars
    async setChannelLayout({ commit, state }, data_) {
      //FIX TEMPORAL 
      state.channelLayout.apiNews = [state.channelLayout.apiNews[0]]

      const videos = state.channelLayout.videos;
      const banners = state.channelLayout.id_layout == 3 ? [] : state.channelLayout.banners;
      const bannersH = state.channelLayout.id_layout == 4 ? state.channelLayout.bannersH : [];
      
      let widgets = [1, 2, 3].includes(+state.channelLayout.id_layout) ? 
        Object.entries(state.channelLayout.widgets)
          .sort((entryA, entryB) => +entryA[0] - +entryB[0])
          .map(entry => entry[1]) // crea array con widgets ordenados según rank
        : [];
        widgets = state.channelLayout.id_layout == 3 ? widgets.slice(0, 2) : widgets;
        widgets = [2, 3].includes(+state.channelLayout.id_layout) ?
          widgets
            .concat(state.channelLayout.apiNews.map(el => ({ ...el, id_objeto:4, tipo_widget:"2", nombre_widget:"RRSS", tiempo_ejecucion:"0" })))
          : widgets;
      const payload = {
        id_canal: +state.channelLayout.id_canal,
        lista: {
          id_lista: +data_.id_lista ?? undefined,
          id_layout: +state.channelLayout.id_layout,
          logo: state.channelLayout.logo.src ? state.channelLayout.logo.src : "",
          formato_logo: "image/" + String(state.channelLayout.logo.format),
          color: state.channelLayout.color,
          videos: videos.map(video => {
            return {
              id_archivo_lista: +video.id_file ?? 0,
              id_archivo_repositorio: +video.id_archivo, // los que vienen del repo
              id_video: +video.id_video, // los que vienen de Videos
              tipo_archivo: "1",
              tiempo_ejecucion: video.tiempo_video,
              total_tiempo: state.channelLayout.tiempo_videos, // tiempo total de playlist
              fecha_activacion: null
            }
          }),
          banners: banners.map(banner => {
            return {
              id_archivo_lista: +banner.id_file ?? 0,
              id_archivo_repositorio: +banner.id_archivo,
              id_video: null,
              tipo_archivo: "2",
              tiempo_ejecucion: moment()
                .startOf('day')
                .add(state.channelLayout.timePerBanner, 'seconds')
                .format("HH:mm:ss"),
              total_tiempo: moment()
                .startOf('day')
                .add(state.channelLayout.timePerBanner * state.channelLayout.banners.length, 'seconds')
                .format("HH:mm:ss"),
              fecha_activacion: null
            }
          }),
          banners_horizontales: bannersH.map(banner => {
            return {
              id_archivo_lista: +banner.id_file ?? 0,
              id_archivo_repositorio: +banner.id_archivo,
              id_video: null,
              tipo_archivo: "5",
              tiempo_ejecucion: moment()
                .startOf('day')
                .add(state.channelLayout.timePerBannerH, 'seconds')
                .format("HH:mm:ss"),
              total_tiempo: moment()
                .startOf('day')
                .add(state.channelLayout.timePerBannerH * state.channelLayout.bannersH.length, 'seconds')
                .format("HH:mm:ss"),
              fecha_activacion: null
            }
          }),
          widgets: widgets
            .map(widget => ({
              id_widget: +widget.id_widget ?? 0,
              id_objeto: +widget.id_objeto,
              tipo_widget: widget.tipo_widget,
              tiempo_ejecucion: moment()
                .startOf('day')
                .add(widget.tiempo_ejecucion, 'seconds')
                .format("HH:mm:ss"),
              ubicaciones_clima: widget.nombre_widget === "Weather API" ? 
                widget.ubicaciones_clima.map(locObj => ({ 
                  ubicacion: locObj.ubicacion, 
                  latitud: locObj.latitud, 
                  longitud: locObj.longitud 
                })) : null,
              dias_accidente: widget.nombre_widget === "Días sin accidente" ? widget.dias_accidente : null,
              id_rrss: widget.nombre_widget === "RRSS" ? +widget.id_rrss : null
            })
          )
        }
      };

      const params = {
        mutation: data_.id_lista ? EDIT_CHANNEL_LAYOUT : SAVE_CHANNEL_LAYOUT,
        variables: payload
      };

      let response = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) response = data_.id_lista ? res.data.UpdateListaReproduccion : res.data.SaveListaReproduccion;
      } catch (e) {
        console.log("response de cargar contenido al canal: ", e);
        console.log(e.networkError.result.errors);
      }

      return response;
    },

    async getCanal({ commit }, data_) {
      const params = {
        query: GET_ONE_CANAL,
        variables: data_,
        fetchPolicy: 'network-only'
      };
      
      let response = [];
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.GetOneCanal;
        commit("getCanal", response);
      } catch (e) {
        console.log("error en getCanal: ", e);
        console.log(e.networkError.result.errors);
        response = null;
      }

      return response;
    },

    async getWidgets() {
      const params = {
        query: GET_ALL_WIDGETS
      };
      
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.GetAllWidgets;
      } catch (e) {
        console.log("error en getWidgets: ", e);
        console.log(e.networkError.result.errors);
      }

      return response;
    },

    async getNoticiasOpciones() {
      const params = {
        query: GET_NOTICIAS_OPCIONES
      };
      
      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.FindNoticiasOpciones;
      } catch (e) {
        console.log("response de getNoticiasOpciones: ", e);
        console.log(e.networkError.result.errors);
      }

      return response;
    },

    uploadFileSubscription(_, data_) {
      const {
        id_empresa,
        id_usuario,
        tipo_archivo,
        file
      } = data_;
      const duration = new Date(file.duration * 1000).toISOString().slice(11, 19);
      const payload = {
        id_empresa,
        id_usuario,
        descripcion_archivo: "",
        tiempo:         String(duration),
        tipo_archivo:   tipo_archivo,
        nombre_archivo: file.name,
        archivo_b64:    file.src,
        formato_b64:    file.type,
      };
      const params = {
        query: SAVE_FILE_REPO,
        variables: payload
      };

      return apolloClient.subscribe(params);
    },

    async deleteFileRepositorio({ commit }, data_) {
      const {
        id_archivo,
        type
      } = data_;
      const params = {
        mutation: DELETE_FILE_REPOSITORIO,
        variables: {id_archivo}
      };
      
      let deleted = null;
      try {
        const res = await apolloClient.mutate(params);
        if (!res.loading) deleted = res.data.eliminarArcivoRepositorio;
        console.log("response de deleteFileRepositorio: ", deleted);
        commit("deleteRepoFile", {deleted, type});
      } catch (e) {
        console.log("error de eliminar archivo del repo: ", e);
      }

      return deleted;
    },

    async getRepoFiles(_, data_) {
      const params = {
				query: GET_PAGINAS_FILES_REPOSITORIO,
				variables: data_
			};

      let response = null;
			try {
				let data = null;
				response = await apolloClient.query(params);
        if (!response.loading) data = response.data.PaginasFilesRepositorio;
        response = data;
			} catch (e) {
				console.log("response de getRepoFiles: ", e);
			}

			return response;
    },

    async getFilesPagination({ commit }, data_) {
      console.log('post',data_);
      
      let response = null;
			try {
				const res = await apolloClient.query({
          query: GET_TOTAL_FILES_REPOSITORIO,
          variables: data_,
          fetchPolicy: "network-only"
        });
        if (!res.loading) response = res.data.TotalFilesRepositorio;

        commit("setPagination", { pagination: response, payload: data_ });
			} catch (e) {
				console.log("error en getFilesPagination: ", e);
			}

			return response;
    },

    async getAllRepoFiles({ dispatch, commit }, data_) {
      Object.assign(data_, {
        nombreArchivo: ""
      });

			const paginatedFiles = await dispatch("getFilesPagination", data_);      

      let allFiles = null;
      if (paginatedFiles !== null) {
        let repoFilesIds = [];
        paginatedFiles.forEach(page => {
          repoFilesIds = repoFilesIds.concat(page.ids);
        });
        allFiles = await dispatch("getRepoFiles", { idEmpresa: data_.idEmpresa, idArchivo: repoFilesIds });
        commit("setAllRepoFiles", allFiles);
      } else {
				console.log("getAllRepoFiles. error en getFilesPagination. respuesta null");
      }
      
			return allFiles;
    }
  },
};