import { apolloClient } from "../../../vue-apollo";
import {
	PAGINAS_GALERIAS,
	LIST_PAGINADA_GALERIA,
	GET_GALERIA
} from "../../../apollo/queries";
import {
	DELETE_GALERIA,
	EDIT_GALERIA,
	CREATE_GALERIA,
	CAMBIAR_ESTADO_GALERIA
} from "../../../apollo/mutations";

export default {
	namespaced: true,
	state: {
		album_list: [],
		paginas_galeria: []
	},
	mutations: {
		getGalleries(state, galleries) {
			state.album_list = galleries;
		},
		addGallery(state, gallery) {
			state.album_list.unshift(gallery);
		},
		deleteGallery(state, id) {
			// se guarda la nueva data en el array que se usa para mostrar las galeria
			let index = state.album_list.findIndex((album) => album.id_galeria == id);
			state.album_list.splice(index, 1);
		},
		editGallery(state, editedGallery) {
			// eslint-disable-next-line no-unused-vars
			const { total_likes_admin, ...editedGalleryTemp } = editedGallery;
			const editedGalleryIdx = state.album_list.findIndex(el => el.id_galeria == editedGallery.id_galeria);
			if (editedGalleryIdx !== -1) Object.assign(state.album_list[editedGalleryIdx], editedGalleryTemp);
			// state.album_list.splice(editedGalleryIdx, 1, editedGallery);
		},

		SET_PAGINAS_GALERIA(state, paginas) {
			state.paginas_galeria = paginas;
		}
	},
	actions: {

		async addGalleryAction({ commit }, payload) {
			const params = {
				mutation: CREATE_GALERIA,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.crearGaleria;
					response = data;
				});
			} catch (e) {
				console.log("response de crear galería: ", e);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("addGallery", response);
				} else {
					resolve(false);
				}
			});
		},

		async deleteGalleryAction({ commit }, payload) {
			const params = {
				mutation: DELETE_GALERIA,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.eliminarGaleria;
					response = data;
				});
			} catch (e) {
				console.log("response de eliminar galería: ", e);
				console.log(e.networkError.result.errors);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					console.log('deleteGalleryAction response:', response);
					commit("deleteGallery", response.id_galeria);
				} else {
					resolve(false);
				}
			});
		},

		async editGalleryAction({ commit }, payload) {
			const params = {
				mutation: EDIT_GALERIA,
				variables: payload
			};

			let response = null;
			try {
				let data = null;
				await apolloClient.mutate(params).then(res => {
					if (!res.loading) data = res.data.editarGaleria;
					response = data;
				});
			} catch (e) {
				console.log("response de editar galería: ", e);
				console.log(e.networkError.result.errors);
			}

			return new Promise((resolve) => {
				if (response != null) {
					resolve(response);
					commit("editGallery", response);
				} else {
					resolve(false);
				}
			});
		},

		async changeGalleryStateAction({ commit }, data_) {
			let params = {
				mutation: CAMBIAR_ESTADO_GALERIA,
				variables: data_,
			};

			let response = null;
			try {
				const res = await apolloClient.mutate(params);
				if (!res.loading) response = res.data.cambiarEstadoGaleria;
				commit("editGallery", response);
			} catch (e) {
				console.log("response de cambiar estado de galería: ", e);
				console.log(e.networkError.result.errors);
			}
			return response
		},

		async getAlbumByIdAction(_, payload) {
      const params = {
        query: GET_GALERIA,
        variables: payload,
      };

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.galeria;
      } catch (e) {
        console.log("Falla error, getAlbumById ", e);
      }
      return response;
		},


		// Metodo para capturar las paginas de galería
		async getPaginasGaleria({ commit }, data_) {
			// seguir con el paginated
			let response = null;
			let params = {
				query: PAGINAS_GALERIAS,
				variables: data_,
				fetchPolicy: 'network-only'
			}
			try {
				await apolloClient.query(params).then(res => {
					//console.log("respuest de server getPaginacionSolicitudes: ", res.data.PaginasGaleriaAdmin);
					if (!res.loading) response = res.data.PaginasGaleriaAdmin;
				});
			}
			catch (e) {
				console.log("Falla error, getPaginasGaleria ", e);
				//return false
			}
			return new Promise((resolve) => {
				if (response != null && response.length > 0) {
					commit("SET_PAGINAS_GALERIA", response);
					resolve(response);
				}
				else {
					commit("SET_PAGINAS_GALERIA", response);
					commit("getGalleries", response);
					resolve(false);
				}
			});
		},

		// metodo de paginacion y envia los datos correspondiente a muro según pagina, esto depreca metodo de getAllPostsByPage
		async getGaleriaPaginasCompleto({ dispatch, commit }, payload) {
			let response = null;
			const paginatedGaleria = await dispatch("getPaginasGaleria", payload.data_);
			let pagina = payload.pagina;
			let ids_galeria = [];
			if (paginatedGaleria) {
				paginatedGaleria.forEach((data) => {
					if (data.pagina == pagina) {
						ids_galeria = data.galerias;
					}
				});
			}
			//console.log("dato de paginacion: ", paginatedReconocimiento);
			let data_ = {
				id_empresa: payload.data_.id_empresa,
				id_usuario: "",
				id_galeria: ids_galeria
			}
			let params = {
				query: LIST_PAGINADA_GALERIA,
				variables: data_,
				fetchPolicy: 'network-only'
			}
			try {
				await apolloClient.query(params).then(res => {
					//console.log("respuest de server getPostPaginasPostCompleto: ", res.data.galeriasIdAdmin);
					if (!res.loading) response = res.data.galeriasIdAdmin;
				});
			}
			catch (e) {
				console.log("Falla error, getPostPaginasPostCompleto ", e);
			}
			return new Promise((resolve) => {
				if (response != null) {
					if (response.length == 0) {
						resolve(false);
					}
					else {
						resolve(response);
						commit("getGalleries", response);
					}
				}
				else {
					resolve(false);
				}
			});
		},

	}
};