<template>
  <div class="card card-shadow border-round-10">
    <div class="card-body">
      <div id="chart">
        <apexchart 
          type="bar" 
          width="100%" 
          height="250" 
          :options="chartOptions"
          :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartOptions: {
        chart: {
          id: "basic-bar",
          fontFamily: "Product Sans Regular",
          locales: [{
            "name": "en",
            "options": {
              "toolbar": {
                "exportToSVG": "Descargar SVG",
                "exportToPNG": "Descargar PNG",
                "exportToCSV": "Descargar CSV",
              }
            }
          }],
          redrawOnWindowResize: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'last',
            columnWidth: "35%",
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            colors: ["#0072f7"],
          },
        },
        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
          style: {
            fontSize: "13px",
            fontFamimly: "Product Sans Bold",
            color: "#999BA6",
          },
        },
        title: {
          text: "Usabilidad mes a mes",
          style: {
            fontSize: "16px",
            fontFamimly: "Product Sans Bold",
            color: "#0072f7",
            cssClass: "label-graph__home",
          },
        },
        fill: {
          colors: ["#0072f7"],
        },
        // fill: {
        //   colors: [function({ value, month }) {
        //     console.log(month)
        //     if(value < 5) {
        //         return '#7E36AF'
        //     } else if (value >= 6 && value < 20) {
        //         return '#164666'
        //     } else {
        //         return '#D9534F'
        //     }
        //   }]
        // }
      },
      series: [
        {
          name: "% Usabilidad",
          data: [0],
        },
      ],
    };
  },

  props: {
    usability: Array,
    month: String
  },

  mounted() {
    this.getGraphData();
  },

  watch: {
    usability(val) {
      if (val.length != 0) {
        this.getGraphData();
      }
    }
  },

  methods: {
    getGraphData() {
      console.log(this.usability, this.month)
      const percentage = this.usability.map((e) =>
        Number.parseFloat(e.percentage)
      );
      console.log("porcentajes", percentage);
      this.series[0].data = percentage;
      window.dispatchEvent(new Event("resize"));
      console.log("datos", this.series[0].data);
    },
  },
};
</script>