import Vue from "vue";
import VueRouter from "vue-router";
import { hotelsList } from "./admin/hotels/routesHotels";
import { benefitList } from "./admin/benefit/routesBenefit";
import { birthdayList } from "./admin/birthday/routesBirthday";
import { covidList } from "./admin/covid/routesCovid";
import { cultureList } from "./admin/culture/routesCulture";
import { galleryList } from "./admin/gallery/routesGallery";
import { homeList } from "./admin/home/routesHome";
import { libraryList } from "./admin/library/routesLibrary";
import { newsList, intraNewsList } from "./admin/news/routesNews";

import { organigramaList } from "./admin/organigrama/routesOrganigrama";
import { recognizeList } from "./admin/recognize/routesRecognize";
import { seguridadList } from "./admin/seguridad/routesSeguridad";
import { videosList } from "./admin/video/routesVideo";
import { loginRoute, loginTokenRoute } from "./login/routesLogin";
import { profileList } from "./profile/routesProfile";

import { userList } from "./admin/users/routesUsers";
import { alertPushList } from "./admin/alertaPush/routesAlert";
import { encuestasList } from "./admin/encuestas/routesEncuestas";
import { transporteList } from "./admin/transporte/routesTransporte";
import { wallList } from "./admin/wall/routesWall";
import { canalCorporativoList } from "./admin/tv/routesTV";

//FF
import { births } from "@/router/admin/births/routesBirths";
import { customization } from "@/router/admin/customization/routesCustomization";


Vue.use(VueRouter);

const routes = [
  { path: "*", redirect: "/inicio" },
  hotelsList,
  benefitList,
  birthdayList,
  covidList,
  cultureList,
  galleryList,
  homeList,
  libraryList,
  newsList,
  intraNewsList,

  organigramaList,
  recognizeList,
  seguridadList,
  videosList,
  loginRoute, loginTokenRoute,
  profileList,

  userList,
  alertPushList,
  encuestasList,
  transporteList,
  wallList,
  canalCorporativoList,

  //FF
  births,
  customization
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
