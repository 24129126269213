<template >

  <section id="muro-digital">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Crea, edita y administra tu Mural Digital estés donde estés.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          :to="{ name: 'CanalCorporativo-add-canales' }"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear canal
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="font-title-bold color-main">
                Total pantallas
            </h3>
            <PantallasPlan 
              :pantallas="CantidadPantallasAdmin.pantallas_contrato" 
            />
          </div>
          <div class="col-12 col-lg-6">
            <h3 class="font-title-bold color-main">
                Estado pantallas
            </h3>
            <div class="row">
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasRegistroCard 
                  :pantallas="CantidadPantallasAdmin.registradas"    
                  :opciones="opciones_pantallas_registradas"
                />
              </div>
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasRegistroCard 
                  :pantallas="CantidadPantallasAdmin.no_registradas" 
                  :opciones="opciones_pantallas_no_registradas"
                />
              </div>
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasConexionCard 
                  :pantallas="CantidadPantallasAdmin.online"
                  :opciones="opciones_pantallas_online"
                  @openModal="openModal('0')"
                />
              </div>
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasConexionCard 
                  :pantallas="CantidadPantallasAdmin.offline"
                  :opciones="opciones_pantallas_offline"
                  @openModal="openModal('1')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tv-dash__modal" v-show="open_modal == true">
        <PantallasTableModal
          :pantallas="PantallasPaginadas"
          @closeModal="closeModal"
          :estado="estadoForModal"
        />
    </div>

  </section>

</template>

<script>
import { mapState, mapActions } from "vuex";
import PantallasPlan from "./PantallasPlan.vue";
import PantallasRegistroCard from "./PantallasRegistroCard.vue";
import PantallasConexionCard from "./PantallasConexionCard.vue";
import PantallasTableModal from "./PantallasTableModal.vue";

export default {
  components: {
    PantallasPlan,
    PantallasRegistroCard,
    PantallasConexionCard,
    PantallasTableModal,
  },
  
  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      PantallasPaginadas: [],
      TotalPantallasOn: [],
      TotalPantallasOff: [],
      open_modal: false,
      estadoForModal: '0',
      opciones_pantallas_registradas: {
        title: "Pantallas registradas",
        estado: "0",
      },
      opciones_pantallas_no_registradas: {
        title: "Pantallas sin registrar",
        estado: "1",
      },
      opciones_pantallas_online: {
        title: "Pantallas Online",
        estado: "0",
      },
      opciones_pantallas_offline: {
        title: "Pantallas Offline",
        estado: "1",
      },
    }
  },

  computed: {
    ...mapState("canalCorporativoModule", ["GetPantallaList", "CantidadPantallasAdmin"])
  },

  mounted() {
    this.getCantidadPantallasAdmin();
    this.getTotalPantallasOnOff();
  },

  methods: {
    ...mapActions("canalCorporativoModule", [
      "GetCantidadPantallasAction",
      "GetCantidadPantallasPaginadasAction",
      "GetCantidadPantallasOnOffAction"
    ]),

    async openModal(estado) {
      let ids_pantallas = [];
      this.estadoForModal = estado;
      if (estado == '0') {
        ids_pantallas = this.TotalPantallasOn.reduce((prev, curr) => prev.concat(curr.ids), []);
      } else if (estado == '1') {
        ids_pantallas = this.TotalPantallasOff.reduce((prev, curr) => prev.concat(curr.ids), []);
      } else {
        console.error("error..")
      }

      await this.getPantallasPaginadas(ids_pantallas);

      this.open_modal = true;
    },

    closeModal() {
      this.PantallasPaginadas = [],
      this.open_modal = false;
    },

    async getCantidadPantallasAdmin() {
      const res = await this.GetCantidadPantallasAction();
      if (!res) {
        this.toastGenericError();
      }
    },

    async getPantallasPaginadas(ids_pantallas = [2, 3, 4, 5, 6]) {
      const payload = {
        idPantalla: ids_pantallas.map(i => +i)
      };
      this.PantallasPaginadas = await this.GetCantidadPantallasPaginadasAction(payload);
      if (!this.PantallasPaginadas) {
        this.toastGenericError();
      }
    },

    async getTotalPantallasOnOff() {
      // TODO: combinar queries con apollo compose
      this.TotalPantallasOn = await this.GetCantidadPantallasOnOffAction({ id_estado_pantalla: 1 })
      this.TotalPantallasOff = await this.GetCantidadPantallasOnOffAction({ id_estado_pantalla: 3 })

      if (!this.TotalPantallasOn || !this.TotalPantallasOff) {
        this.toastGenericError();
      }

    }

  }

};
</script>