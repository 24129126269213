import CanalCorporativo from '@/views/ADMIN/TV.vue'
import TvDashboard from '@/components/tv/TvDashboard.vue'
import HomeCanales from '@/components/tv/HomeCanales.vue'
import AgregarLayout from '@/components/tv/AgregarLayout.vue'
import AgregarVideo from '@/components/tv/AgregarVideo.vue'
import AgregarBanner from '@/components/tv/AgregarBanner.vue'
import AgregarCanal from '@/components/tv/AgregarCanal.vue'
import HomePantallas from '@/components/tv/HomePantallas.vue'
import AgregarPantallas from '@/components/tv/AgregarPantallas.vue'

export const canalCorporativoList = {
  path: "/CanalCorporativo",
  name: "CanalCorporativo",
  component: CanalCorporativo,
  redirect: { name: "CanalCorporativo-dashboard" },
  children: [ {
      path: "dashboard",
      name: "CanalCorporativo-dashboard",
      component: TvDashboard
    }, {
      path: "canales",
      name: "CanalCorporativo-canales",
      component: HomeCanales
    }, {
      path: "contenido-add/:id",
      name: "CanalCorporativo-addContenido",
      component: AgregarLayout
    }, {
      path: "contenido-edit/:id",
      name: "CanalCorporativo-editContenido",
      component: AgregarLayout
    }, {
      path: "listado-videos/:id",
      name: "CanalCorporativo-listadoVideos",
      props: true,
      component: AgregarVideo
    }, {
      path: "listado-banners/:id",
      name: "CanalCorporativo-listadoBanners",
      props: true,
      component: AgregarBanner
    }, {
      path: "listado-banners-h/:id",
      name: "CanalCorporativo-listadoBannersHorizontal",
      props: true,
      component: AgregarBanner
    }, {
      path: "canales-add",
      name: "CanalCorporativo-add-canales",
      component: AgregarCanal
    }, {
      path: "canales-edit/:id",
      name: "CanalCorporativo-edit-canales",
      props: true,
      component: AgregarCanal
    }, {
      path: "pantallas",
      name: "CanalCorporativo-pantallas",
      component: HomePantallas
    }, {
      path: "pantallas-add",
      name: "CanalCorporativo-add-pantallas",
      component: AgregarPantallas
    }, {
      path: "pantallas-edit/:id",
      name: "CanalCorporativo-edit-pantallas",
      props: true,
      component: AgregarPantallas
    },
  ],
};