<template>
  <section id="form-canal">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <!-- <font-awesome-icon icon="tv" class="color-secondary pe-1"/> -->
          <template v-if="create_mode">
            <strong>Crear canal:</strong> Ingresa la información de tu canal.
          </template>
          <template v-else>
            <strong>Editar canal:</strong> Edita los datos informativos de tu canal.
          </template>
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row flex-grow-1 mt-3">
              <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary">Datos de tu canal</h5>
                <div class="card flex-grow-1">
                  <div class="card-body">
                    <div class="row mb-3">
                      <div class="col-12">
                        <label class="text-secondary">Nombre *</label>
                        <input type="text" name="channel_name" class="form-control input-custom" :class="{'is-invalid': nameReq['channel_name']}" 
                        placeholder="Ingresa el nombre de tu canal" maxlength="150" v-model="channel_name" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="text-secondary">Descripción</label>
                        <textarea class="form-control textarea-custom" placeholder="Ingresa una breve descripción del canal. Esta puede incluir detalles de su ubicación." v-model="channel_description" maxlength="75" rows="3">
                          </textarea>
                        <div class="form-text text-end">
                          {{ channel_description!= undefined? channel_description.length: "0" }}/75
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary">Periodicidad de transmisión</h5>
                <div class="card flex-grow-1">
                  <div class="card-body">
                    <label class="text-secondary">Selecciona los días de transmisión *</label>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <input id="diasTodaLaSemana" class="form-check-input me-1" type="radio" value="1" v-model="transmissionDailyCycle" checked>
                        <label class="form-check-label stretched-link" for="diasTodaLaSemana">
                            Toda la semana
                          </label>
                      </li>
                      <li class="list-group-item">
                        <input 
                          id="diasPersonzalizado"
                          class="form-check-input me-1" 
                          type="radio" 
                          value="0"
                          v-model="channel_days"
                          disabled
                        >
                        <label 
                          class="form-check-label stretched-link" 
                          for="diasPersonzalizado">
                          Personalizado (¡Pronto!)
                        </label>
                      </li>
                    </ul>
                    <div v-if="transmissionDailyCycle === '0'" class="row mt-3">
                      <div class="col-12">
                        <h6 class="font-main text-secondary border-bottom mt-1 pb-2">
                          Selecciona los días
                        </h6>
                        <ul class="form-days list-inline">
                          <li class="list-inline-item">
                            <button @click="selectDay(1)" class="form-days-item" :class="getSelectedButton(1) ? 'active' : ''" :disabled="active_week == false">
                                L
                              </button>
                          </li>
                          <li class="list-inline-item">
                            <button @click="selectDay(2)" class="form-days-item" :class="getSelectedButton(2) ? 'active' : ''" :disabled="active_week == false">
                                M
                              </button>
                          </li>
                          <li class="list-inline-item">
                            <button @click="selectDay(3)" class="form-days-item" :class="getSelectedButton(3) ? 'active' : ''" :disabled="active_week == false">
                                X
                              </button>
                          </li>
                          <li class="list-inline-item">
                            <button @click="selectDay(4)" class="form-days-item" :class="getSelectedButton(4) ? 'active' : ''" :disabled="active_week == false">
                                J
                              </button>
                          </li>
                          <li class="list-inline-item">
                            <button @click="selectDay(5)" class="form-days-item" :class="getSelectedButton(5) ? 'active' : ''" :disabled="active_week == false">
                                V
                              </button>
                          </li>
                          <li class="list-inline-item">
                            <button @click="selectDay(6)" class="form-days-item" :class="getSelectedButton(6) ? 'active' : ''" :disabled="active_week == false">
                                S
                              </button>
                          </li>
                          <li class="list-inline-item">
                            <button @click="selectDay(7)" class="form-days-item" :class="getSelectedButton(7) ? 'active' : ''" :disabled="active_week == false">
                                D
                              </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <label class="text-secondary mt-3">Selecciona el horario de transmisión *</label>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <label class="form-check-label stretched-link" for="horario24Horas">
                        <input 
                          id="horario24Horas" 
                          class="form-check-input me-1" 
                          type="radio" 
                          value="1"
                          v-model="channel_hours"
                          checked
                        >
                          24 Horas
                        </label>
                      </li>
                      <li class="list-group-item">
                        <input 
                          id="horarioPersonzalizado"
                          class="form-check-input me-1" 
                          type="radio" 
                          value="0"
                          v-model="channel_hours"
                          disabled
                        >
                        <label class="form-check-label stretched-link" for="horarioPersonzalizado">
                          Personalizado (¡Pronto!)
                        </label>
                      </li>
                    </ul>
                    <div v-if="transmissionHourlyCycle === '0'" class="row mt-3">
                      <div class="col-12">
                        <h6 class="font-main text-secondary border-bottom mt-1 pb-2">
                          <!-- <font-awesome-icon icon="calendar" class="color-secondary"/> -->
                          Selecciona los días
                        </h6>
                        <div class="row mt-3">
                          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                            <label class="text-secondary">Horario de inicio</label>
                            <div class="d-flex">
                              <div class="flex-grow-1">
                                <input class="form-control input-custom" type="number" min="00" max="23" value="00" :disabled="active_hours == false" v-model="inicio_hora">
                              </div>
                              <div class="d-flex justify-content-center align-items-center px-2">
                                :
                              </div>
                              <div class="flex-grow-1">
                                <input class="form-control input-custom" type="number" min="00" max="59" value="00" :disabled="active_hours == false" v-model="inicio_min">
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <label class="text-secondary">Horario de término</label>
                            <div class="d-flex">
                              <div class="flex-grow-1">
                                <input class="form-control input-custom" type="number" min="00" max="23" value="23" :disabled="active_hours == false" v-model="termino_hora">
                              </div>
                              <div class="d-flex justify-content-center align-items-center px-2">
                                :
                              </div>
                              <div class="flex-grow-1">
                                <input class="form-control input-custom" type="number" min="00" max="59" value="59" :disabled="active_hours == false" v-model="termino_min">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
                  <router-link class="btn btn-custom-color-white border border-round-50 mw-100 me-2" :to="{ name: 'CanalCorporativo-canales' }">
                    Volver
                  </router-link>
                  <span
                    content="Debes completar los campos <br> obligatorios (*) y asegura <br> que el nombre tenga <br>al menos 5 caracteres." 
                    v-tippy="{ placement : 'top', arrow:true, trigger: active_button == false?'mouseenter focus':'' }"
                    v-on:mouseover="highlight()"
                    v-on:mouseleave="cleanHighlight()"

                  >
                    <button 
                      v-show="create_mode == true" 
                      class="btn btn-custom-color-blue border-round-50 mw-100" 
                      @click="addChannel()" 
                      :disabled="active_button == false"
                      >
                        Crear
                      </button>
                  </span>
                  <span
                    content="Debes completar los campos <br> obligatorios (*) y asegura <br> que el nombre tenga <br>al menos 5 caracteres." 
                    v-tippy="{ placement : 'top', arrow:true, trigger: active_button == false?'mouseenter focus':'' }"
                    v-on:mouseover="highlight()"
                    v-on:mouseleave="cleanHighlight()"

                  >
                  <button v-show="create_mode == false" 
                  class="btn btn-custom-color-blue border-round-50 mw-100" 
                  @click="editChannel()" 
                  :disabled="active_button == false">
                  Guardar
                </button>
              </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-show="show_spinner" />
  </section>
</template>
 
<script>
import {
  mapActions,
  mapState,
  mapGetters
} from "vuex";
import Spinner from "../Spinner.vue";
import moment from 'moment';
export default {
  props: {
    id: String
  },
  data() {
    return {
      show_spinner: false,
      channel_name: "",
      channel_description: "",
      active_button: false,
      create_mode: true,
      transmissionDailyCycle: "1",
      transmissionHourlyCycle: "1",
      active_week: false,
      active_hours: false,
      inicio_hora: "00",
      inicio_min: "00",
      termino_hora: "00",
      termino_min: "00",
      dias_frecuencias: [],
      days_ok: false,
      channel_days: "",
      channel_hours: "",
      nameReq: {
        "channel_name":false  
      },
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.channel_days = "1";
    this.channel_hours = "1"
    if (id != undefined) {
      console.log({length:this.listCanales.length});
      if(this.listCanales.length===0){
        await this.GetListCanalesAction( { id_estado: 5 });
      }
      
      const channel = await this.getChannelById(Number(id))
      console.log({channel});
      
      this.create_mode = false;
      
      this.channel_name = channel.nombre_canal;
      this.channel_description = channel.descripcion_canal;
      //TODO: Revisar 
      //this.channel_days = channel.reproduccion_dia;
      //this.channel_hours = channel.reproduccion_hora;

      if (channel.reproduccion_hora !=1 || this.channel_days.reproduccion_dia != 1) 
      {
        if (channel.frecuencia.length>0)
        {   if (channel.frecuencia[0].hora_inicio!=null){
              let formatoHoraInicio = channel.frecuencia[0].hora_inicio.split(":");
              let formatoHoraTermino = channel.frecuencia[0].hora_fin.split(":");
              this.inicio_hora = formatoHoraInicio[0];
              this.inicio_min = formatoHoraInicio[1];
              this.termino_hora = formatoHoraTermino[0];
              this.termino_min = formatoHoraTermino[1];
            }
            if(this.channel_days==0){
                channel.frecuencia.forEach(data=> 
                  this.dias_frecuencias.push(parseInt(data.num_dia)) 
                );
              }
        } 
      }
    } 
  },
  watch: {
    channel_hours:{
      handler(hh) {
        if (this.channel_name.length != 0 && this.days_ok && hh !== "") {
         this.active_button = true;
        } else this.active_button = false;
      }
    },
    channelConfig: {
      handler(newVal , oldVal) {
        if (this.transmissionHourlyCycle == "0") {
          this.active_hours = true;
          this.channel_hours = this.transmissionHourlyCycle;
        } else if (this.transmissionHourlyCycle == "1") {
          this.active_hours = false;
        }
        if (this.transmissionDailyCycle == "0") {
          this.active_week = true;
          this.days_ok = this.dias_frecuencias.length != 0;
          if (oldVal.transmissionDailyCycle == "1") {
            this.dias_frecuencias = [];
          }
        } else if (this.transmissionDailyCycle == "1") {
          this.active_week = false;
          this.days_ok = true;
          if (oldVal.transmissionDailyCycle == "0" || oldVal.transmissionDailyCycle == "") {
            this.dias_frecuencias = [1,2,3,4,5,6,7];
          }
        }
        if (this.channel_name.trim().length > 4 && this.days_ok && this.channel_hours !== "") {
         this.active_button = true;
        } 
        else this.active_button = false;
      },
      deep: true
    },
  },

  computed: {
    ...mapGetters("canalCorporativoModule", ["getChannelById"]),
    ...mapState("canalCorporativoModule", ["listCanales"]),
    channel() {
      const { id } = this.$route.params;
      return { ...this.getChannelById(id) };
    },
    channelConfig() {
      const {
        channel_name,
        transmissionDailyCycle,
        transmissionHourlyCycle,
        dias_frecuencias
      } = this;
      return {
        channel_name,
        transmissionDailyCycle,
        transmissionHourlyCycle,
        dias_frecuencias
      };
    }
  },
  
  methods: {
    ...mapActions("canalCorporativoModule", ["addCanal", "editCanal","GetListCanalesAction"]),
    
    async addChannel() {
      const horaInicioDuration = moment.duration(`${this.inicio_hora}:${this.inicio_min}`);
      const horaTerminoDuration = moment.duration(`${this.termino_hora}:${this.termino_min}`);
      const horaInicioFormatted = moment.utc(horaInicioDuration.as('milliseconds')).format('HH:mm:ss');
      const horaTerminoFormatted = moment.utc(horaTerminoDuration.as('milliseconds')).format('HH:mm:ss');
      let data = {
        nombre_canal: this.channel_name.trim(),
        descripcion_canal: this.channel_description,
        dias_frecuencia: this.dias_frecuencias,
        hora_inicio: horaInicioFormatted,
        hora_fin: horaTerminoFormatted
      };
      this.show_spinner = true;
      const resAdd = await this.addCanal(data);
      if (!resAdd) {
        this.$toast.open({
          message: "Falla al momento de agregar canal",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: "Tu canal ha sido agregado correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
        this.$router.push({
          name: "CanalCorporativo-canales"
        });
      }
      this.show_spinner = false;
    },

    async editChannel() {
      let hora_inicio = "";
      let hora_fin = "";
      if (this.transmissionHourlyCycle == 0) {
        hora_inicio = this.inicio_hora + ":" + this.inicio_min + ":00";
        hora_fin = this.termino_hora + ":" + this.termino_min + ":00";
      }
      else{
        hora_inicio = "00:00:00"
        hora_fin = "00:00:00"
      }
      let data = {
        nombre_canal: this.channel_name.trim(),
        descripcion_canal: this.channel_description,
        dias_frecuencia: this.dias_frecuencias,
        hora_inicio,
        hora_fin,
        id_canal: +this.$route.params.id
      }
      this.show_spinner = true;
      const resEdit = await this.editCanal(data);
      if (!resEdit) {
        this.$toast.open({
          message: "Falla al momento de editar canal, favor contactar al administrador",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      } else {
        this.$toast.open({
          message: "Tu canal ha sido editado correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
        this.$router.push({ name: "CanalCorporativo-canales" });
      }
      this.show_spinner = false;

    },
    
    highlight(){
      Object.keys(this.nameReq).forEach((name) => {
        const elemnt = document.querySelector(`[name="${name}"]`);
        if(elemnt.value == ""){
          this.nameReq[name] = true;
        }else{
          this.nameReq[name] = false;
        }
      })
    },
    cleanHighlight(){
      Object.keys(this.nameReq).forEach((name) => 
        this.nameReq[name] = false
      )
    },

    selectDay(day) {
      let found_index = this.dias_frecuencias.findIndex(dias => dias == day);
      if (found_index == -1) {
        this.dias_frecuencias.push(day)
      } else {
        this.dias_frecuencias.splice(found_index, 1);
      }
    },
    getSelectedButton(day) {
      let existe = this.dias_frecuencias.find((dias) => dias == day);
      let status = false;
      if (existe) {
        status = true;
      }
      return status;
    }
  },
  components: {
    Spinner
  },
};
</script>