<template>
   <div class="login">
      <div class="login-container text-center">
         <div class="login-container-top">
            <h1 class="welcome">¡BIENVENID@!</h1>
         </div>
         <div class="login-container-bottom">
            <form>
               <div class="form-item email">
                  <div class="input-container">
                     <input
                        type="text"
                        placeholder="Ingresa tu RUT o tu correo"
                        v-model.trim="email"
                        required
                        @keydown.space.prevent
                        @keypress=     "validLoginInput(email)"
                        @keyup.delete= "validLoginInput(email)"
                     />
                  </div>
               </div>
               <div class="form-item password">
                  <div class="input-container">
                     <input
                        type="password"
                        v-model="password"
                        placeholder="Contraseña"
                        @keypress.enter="logIn()"
                        required
                     />
                  </div>
               </div>
               <div
                  v-if="error_pass_mail"
                  class="form-item err"
                  :class="error_pass_mail && 'active-err'"
               >
                  Correo o contraseña invalidas
               </div>
               <div class="form-item login-btn">
                  <a
                     class="btn"
                     @click="logIn()"
                     :class="activeLoginBtn() && 'active'"
                  >
                     Iniciar Sesión
                  </a>
               </div>
            </form>
            <div class="forgot-pass mt-2" @click="openRecoverPassModal()">
               ¿Has olvidado tu contraseña?
            </div>
         </div>
      </div>
      <div class="login-footer">
         <div class="made-with">
            Creado con
            <img :src="heart" alt="" class="heart" />
            por &nbsp; <strong>CARPETRES</strong>
         </div>
         <img :src="background_white" alt="" class="back" />
      </div>
      <!-- Modal para recuperar contraseña -->
      <RecoverPassModal v-if="recoverPassModal" @closeModal="closeRecoverPassModal()"/>
      <Spinner v-if="show_spinner" />
   </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Spinner from "../components/Spinner.vue";
import RecoverPassModal from "./RecoverPassModal.vue";

export default {
   components: { Spinner, RecoverPassModal },

   data() {
      return {
         email: "",
         password: "",
         login_type: null,
         recoverPassModal: false,
         valid_mail: false,
         incorrect_mail: false,
         error_pass_mail: false,
         heart: require("@/assets/img/corazon.svg"),
         background_white: require("@/assets/img/background_white.svg"),
         show_spinner: false,
      };
   },
   
   watch: {
      email: function () {
         setTimeout(() => {
            this.validLoginInput(this.email);
         }, 2000);
      },
   },
   
   computed: {
      ...mapState("sidebar", ["modules"]),
      ...mapGetters("sidebar", [ "userHasOnlyOneModule"]),
   },

   created() {
      if (this.$ls.get("user")) {
         this.$router.push('/');
      } 
   },
   
   mounted() {
      this.validLoginInput(this.email);
   },

   methods: {
      ...mapActions("sidebar", ["getModules"]),
      ...mapActions("loginModule", [
         "getUserDataFromTokenAction", 
         "loginAdmin",
         "recoverPassword",
         "getAuthTokens"
      ]),

      openRecoverPassModal() {
         this.recoverPassModal = true;
      },

      closeRecoverPassModal() {
         this.recoverPassModal = false;
      },

      async logIn() {
         if (
            this.email.length > 5 &&
            this.password.length > 3 &&
            this.login_type !== null
         ) {
            this.show_spinner = true;
            this.error_pass_mail = false;
            const b64_pass = btoa(btoa(btoa(this.password)));

            const payload = {
               login: this.email,
               password: b64_pass,
               tipo_login: this.login_type,
               plataforma: "2",
               tipo_web: "1" /* 1: administrador, 2: intranet */
            };

            const respAuthTokens = await this.getAuthTokens(payload);
            if (!respAuthTokens) {
               this.error_pass_mail = true;
               this.show_spinner = false;
               return;
            }
            localStorage.setItem("access-token", respAuthTokens.access_token);
            const userData = this.decodeJWT(respAuthTokens.user_token);
            await this.processUserData(userData.dataReal);
            this.show_spinner = false;

            if (this.userHasOnlyOneModule) {
               this.redirectToLoneModule(this.modules);
            } else {
               this.$router.push({ name: "Inicio" });
            }

         } else {
            this.validLoginInput(this.email);
         }
      },


      async processUserData(userData) {
         this.sanitizeBackendUserData(userData);
         this.$ls.set("user", userData);
         await this.getUserModules( 
            userData.empresa[0].id_empresa, 
            userData.privilegio[0].id_tipo, 
            userData.grupo!=null && typeof userData.grupo[0]=== 'object' && userData.grupo[0]!=null? userData.grupo[0].id_grupo : "null" 
         );
      },

      async getUserModules(idEmpresa, idPrivilegio, idGrupo) {
         const data = {
            id_empresa: idEmpresa,
            id_privilegio: idPrivilegio,
            id_grupo: idGrupo
         };
         await this.getModules(data);
      },

      sanitizeBackendUserData(resp) {
         if (!resp) {
            // toast error..
            return;
         }
         if (Array.isArray(resp.cargo) && resp.cargo[0]==null && typeof resp.cargo[0]=== 'object'){
            resp.cargo[0] = {
               id_cargo : '0',
               nombre_cargo : "S/N"
            }
         }
         if(Array.isArray(resp.gerencia) && resp.gerencia[0]==null && typeof resp.gerencia[0]=== 'object'){
            resp.gerencia[0] = {
               id_gerencia : '0',
               nombre_gerencia : "S/N"
            }
         }
         if(Array.isArray(resp.grupo) && resp.grupo[0]==null && typeof resp.grupo[0]=== 'object'){
            resp.grupo[0] = {
               id_grupo: "0"
            }
         }
         if(Array.isArray(resp.sucursal) && resp.sucursal[0]==null && typeof resp.sucursal[0]=== 'object'){
            resp.sucursal[0] = {
               id_sucursal : '0',
               nombre_sucursal : "S/N"
            }
         }
         if(Array.isArray(resp.turno) && resp.turno[0]==null && typeof resp.turno[0]=== 'object'){
            resp.turno[0] = {
               id_turno : '0',
               nombre_turno : "S/N"
            }
         }
      },


      validUserEmail(email, from) {
         var regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

         setTimeout(() => {
            if (String(email).toLowerCase().match(regex)) {
               if (from == "login") {
                  this.icon_input_login = "check";
                  this.incorrect_mail = false;
               }
               if (from == "recovery") {
                  this.valid_mail = true;
                  this.incorrect_mail = false;
               }
               return true;
            } else {
               if (from == "login") {
                  this.icon_input_login = "error";
                  this.incorrect_mail = false;
               }
               if (from == "recovery") {
                  this.incorrect_mail = true;
                  this.valid_mail = false;
               }
            }
         }, 1000);
      },

      validLoginInput(input) {
         if (input.length > 5 && input !== "") {
            if (input.includes("@")) { // isNaN(input)
               // si es true quiere decir que es un email adsad
               this.login_type = "1"; // 1 para correo
               this.validUserEmail(input, "login");
            } else {
               // si es false quiere decir que es Rut
               this.login_type = "2"; // 2 para rut
               if (input.length >= 8) {
                  this.icon_input_login = "check";
               } else {
                  this.icon_input_login = "error";
               }
            }
         }
      },
      activeLoginBtn() {
         return this.email !== "" && this.password.length >= 4 ? true : false;
      },
   },
};
</script>
