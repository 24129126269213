<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ModalEditPlanInscripciones",
  data() {
    return {
      userInputPlan: {
        id_planificacion: null,
        nombre_planificacion: "",
        inicio_periodo_semanal: "",
        final_periodo_semanal: "",
        concurrencia_ejecucion_semanal: "",
        id_cadencia: "",
        hora_inicio_periodo: "08:00:00",
        hora_final_periodo: "12:00:00",
        hora_termino: "12:00:00",
      },
      userInputPlanEdit: {},
      // Crear planificacion
      hora_inicio: "01",
      min_inicio: "00",
      horario_inicio: "AM",

      hora_termino: "01",
      min_termino: "00",
      horario_termino: "PM",
      //
      err: '',

      //Dias
      dias: [
        { char: "L", value: "1" },
        { char: "M", value: "2" },
        { char: "X", value: "3" },
        { char: "J", value: "4" },
        { char: "V", value: "5" },
        { char: "S", value: "6" },
        { char: "D", value: "7" },
      ],
      diasHash: {
        1: "Lunes",
        2: "Martes",
        3: "Miercoles",
        4: "Jueves",
        5: "Viernes",
        6: "Sabado",
        7: "Domingo",
      },
    };
  },
  created() {
    this.setDataForEditingPlan();
  },
  methods: {
    ...mapActions("transporteModule", []),
    setDataForEditingPlan() {
      if (this.editingPlanificacion.id_planificacion == null) return;
      this.accion = "Editar";
      const {
        hora_inicio_periodo,
        hora_final_periodo,
        inicio_periodo_day,
        final_periodo_day,
        inicio_periodo_semanal,
        final_periodo_semanal,
        inicio_periodo_month,
        final_periodo_month,
        concurrencia_ejecucion_semanal,
      } = this.editingPlanificacion.tranpsorte_planificacion_detalle;
      
      Object.assign(this.userInputPlan, {
        id_planificacion: this.editingPlanificacion.id_planificacion,
        id_cadencia: this.editingPlanificacion.transporte_planificacion_cadencia.id_cadencia,
        nombre_planificacion: this.editingPlanificacion.nombre_planificacion,
        hora_inicio_periodo,
        hora_final_periodo,
        inicio_periodo_day,
        final_periodo_day,
        inicio_periodo_semanal,
        final_periodo_semanal,
        inicio_periodo_month,
        final_periodo_month,
        concurrencia_ejecucion_semanal,
        edit: false,
      });

      this.getHoraInicio(
        this.editingPlanificacion.tranpsorte_planificacion_detalle
          .hora_inicio_periodo
      );
      this.getHoraTermino(
        this.editingPlanificacion.tranpsorte_planificacion_detalle
          .hora_final_periodo
      );
      this.userInputPlanEdit = Object.assign({}, this.userInputPlan);
      this.$emit("userInputChange",{...this.userInputPlan, edit:false});
    },
    getHoraInicio(hora) {
      this.hora_inicio = hora[0] + hora[1];
      this.min_inicio = hora[3] + hora[4];
    },
    getHoraTermino(hora) {
      this.hora_termino = hora[0] + hora[1];
      this.min_termino = hora[3] + hora[4];
    },
    inicioPeriodoInscripcion(n) {
      this.userInputPlan.inicio_periodo_semanal = n;
    },
    terminoPeriodoInscripcion(n) {
      this.userInputPlan.final_periodo_semanal = n;
    },
    get_hora(hora, min) {
      if (hora.length == "") hora = "08";
      if (min.length == "") min = "00";
      let hour = parseInt(hora);
      hour = hour.toString();
      if (hour.length == 1) hour = "0" + hour;
      if (min.length == 1) min = "0" + min;
      return hour + ":" + min + ":00";
    },
    selectHora(e, status, tipo) {
      let key = e.keyCode || e.charCode;
      if (key != 8) {
        //backspace pressed
        if (this.validateOnlyNumber(status)) {
          if (tipo == "inicio-clicked" || tipo == "termino-clicked") {
            if (status.length == 1) {
              if (status < 10 && status >= 1) {
                status = "0" + status;
              }
            }
            if (status.length > 2 || status == "0") {
              status == "00";
            }
          }
          if (status > 23) {
            status = "00";
          }
          if (tipo == "inicio" || tipo == "inicio-clicked") {
            this.hora_inicio = status;
            this.userInputPlan.hora_inicio_periodo = this.get_hora(
              this.hora_inicio,
              this.min_inicio,
              this.horario_inicio
            );
          }
          if (tipo == "termino" || tipo == "termino-clicked") {
            this.hora_termino = status;
            this.userInputPlan.hora_final_periodo = this.get_hora(
              this.hora_termino,
              this.min_termino,
              this.horario_termino
            );
          }
        } else {
          if (tipo == "inicio") this.hora_inicio = "";
          if (tipo == "termino") this.hora_termino = "";
        }
      }
    },
    validateOnlyNumber(text) {
      let num_regex = /^[0-9]*$/g;
      if (!num_regex.test(text)) {
        return false;
      } else {
        return true;
      }
    },
    disableIni(num) {
      const numb = parseInt(num);
      const fin = parseInt(this.userInputPlan.final_periodo_semanal);
      return fin && numb > fin;
    },
    disableFin(num) {
      const numb = parseInt(num);
      const ini = parseInt(this.userInputPlan.inicio_periodo_semanal);
      return ini && numb < ini;
    },
    selectMin(e, status, tipo) {
      let key = e.keyCode || e.charCode;
      if (key != 8) {
        if (this.validateOnlyNumber(status)) {
          if (tipo == "inicio-clicked" || tipo == "termino-clicked") {
            if (status.length == 1) {
              if (status < 10 && status >= 1) {
                status = '0'+status
              } 
             if (status == '0') {
              status = '00';
            }
          }
          }
          if (status > 59) {
            status = '00';
          }
        
          if (tipo == "inicio" || tipo == "inicio-clicked") {
            this.min_inicio = status;
            this.userInputPlan.hora_inicio_periodo = this.get_hora(
              this.hora_inicio,
              this.min_inicio,
              this.horario_inicio
            );
          }
          if (tipo == "termino" || tipo == "termino-clicked") {
            this.min_termino = status;
            this.userInputPlan.hora_final_periodo = this.get_hora(
              this.hora_termino,
              this.min_termino,
              this.horario_termino
            );
          }
        } else {
          if (tipo == "inicio") this.min_inicio = "";
          if (tipo == "termino") this.min_termino = "";
        } 
      }
    },
    toUpdate() {
      const str = JSON.stringify(this.userInputPlan)
      const strEdit = JSON.stringify(this.userInputPlanEdit)
      this.err = '';
      if(str == strEdit) {
        console.log("no hay cambios");
        
        this.$emit("userInputChange",{...this.userInputPlan, edit:true});
        return;
      }
      if( this.userInputPlan.inicio_periodo_semanal == 
          this.userInputPlan.final_periodo_semanal)
      {
        const [hI,mI] = this.userInputPlan.hora_inicio_periodo.split(":") //: "08:00:00",
        const [hF,mF] =this.userInputPlan.hora_final_periodo.split(":")//: "12:00:00",
        const totalMinutosInicio =  (parseInt(hI) * 60) + parseInt(mI);
        const totalMinutosFin =     (parseInt(hF) * 60) + parseInt(mF);
        if (totalMinutosFin <= totalMinutosInicio) {
          console.log("hora mal seteada");
          this.$emit("userInputChange",{...this.userInputPlan, edit:true});
          this.err = 'ya que es el mismo dia, la hora de termino debe ser mayor a la hora de inicio'
          return
        }
      }
      console.log("good");
      this.$emit("userInputChange",{...this.userInputPlan, edit:false});
      
    },
  },
  computed: {
    ...mapState("transporteModule", ["editingPlanificacion"]),
  },
  watch: {
    userInputPlan: {
      handler() {
        this.toUpdate();
      },
      deep: true,
    },
  },
};
</script>
<template>
  <div class="card col-11 mx-auto p-0 border-0">
    <div class="row">
      <div class="col-12">
        <h6 class="font-main-bold color-main border-bottom pb-1 mb-1">
          Detalles de planificación
        </h6>
        <div class="row">
          <div class="col-6">
            <p>Nombre: {{ userInputPlan.nombre_planificacion }}</p>
          </div>
          <div class="col-6">
            <p>
              Día del viaje:
              {{ diasHash[userInputPlan.concurrencia_ejecucion_semanal] || "" }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6 class="font-main-bold color-main border-bottom pb-1 mb-1">
          Campos editables
        </h6>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="row">
        <div class="mt-3 col-lg-6 col-12">
          <div class="col-12 m-auto">
            <label class="text-secondary mb-3">Inicio del periodo</label>
            <ul class="form-days list-inline">
              <template v-for="dia in dias">
                <li class="list-inline-item" :key="'inicio' + dia.value">
                  <a
                    @click="inicioPeriodoInscripcion(dia.value)"
                    class="form-days-item"
                    :class="{
                        'active': userInputPlan.inicio_periodo_semanal == dia.value
                    }"
                    href="javascript:"
                  >
                    {{ dia.char }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="mt-3 col-lg-6 col-12">
          <div class="col-12">
            <label class="text-secondary">Horario de inicio</label>
            <div class="d-flex">
              <div class="flex-grow-1">
                <input
                  class="form-control input-custom"
                  type="number"
                  v-model="hora_inicio"
                  min="1"
                  max="12"
                  placeholder="00"
                  @keyup="selectHora($event, hora_inicio, 'inicio')"
                  @focusout="selectHora($event, hora_inicio, 'inicio-clicked')"
                />
              </div>
              <div
                class="d-flex justify-content-center align-items-center px-2"
              >
                :
              </div>
              <div class="flex-grow-1">
                <input
                  class="form-control input-custom"
                  type="number"
                  v-model="min_inicio"
                  placeholder="00"
                  min="00"
                  max="59"
                  @keyup="selectMin($event, min_inicio, 'inicio')"
                  @focusout="selectMin($event, min_inicio, 'inicio-clicked')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-3 col-lg-6 col-12">
          <div class="col-12">
            <label class="text-secondary mb-3">Término periodo</label>
            <ul class="form-days list-inline">
              <template v-for="dia in dias">
                <li class="list-inline-item" :key="'termino' + dia.value">
                  <a
                    @click="terminoPeriodoInscripcion(dia.value)"
                    class="form-days-item"
                    :class="{
                        'active': userInputPlan.final_periodo_semanal == dia.value
                    }"
                    href="javascript:"
                  >
                    {{ dia.char }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="mt-3 col-lg-6 col-12">
          <div class="col-12">
            <label class="text-secondary mb-1">Horario del término</label>
            <div class="d-flex">
              <div class="flex-grow-1">
                <input
                  class="form-control input-custom"
                  type="number"
                  min="0"
                  max="23"
                  v-model="hora_termino"
                  @keyup="selectHora($event, hora_termino, 'termino')"
                  @focusout="
                    selectHora($event, hora_termino, 'termino-clicked')
                  "
                />
              </div>
              <div
                class="d-flex justify-content-center align-items-center px-2"
              >
                :
              </div>
              <div class="flex-grow-1">
                <input
                  class="form-control input-custom"
                  type="number"
                  v-model="min_termino"
                  min="0"
                  max="59"
                  @keyup="selectMin($event, min_termino, 'termino')"
                  @focusout="selectMin($event, min_termino, 'termino-clicked')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-show="err" class="text-danger col-11 mx-auto mt-1 px-0 py-1 text-center rounded bg-danger bg-opacity-25 ">
      {{ this.err}}
    </p>
  </div>
</template>
