import Video from '@/views/ADMIN/Video.vue'
import VideoList from '@/components/VideosSection/List.vue'
import VideoAdd from '@/components/VideosSection/VideoAddPrincipal.vue'
import VideoCategory from '@/components/VideosSection/VideoCategory.vue'

export const videosList = {
    path: "/videos",
    name: "Videos",
    component: Video,
    redirect: { name: "videos-list" },
    children: [{
            path: "list",
            name: "videos-list",
            component: VideoList
        }, {
            path: "add",
            name: "videos-add",
            component: VideoAdd
        }, {
            path: "edit/:id",
            name: "videos-edit",
            component: VideoAdd
        }, {
            path: "category",
            name: "videos-category",
            component: VideoCategory
        },
    ],
};