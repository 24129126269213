import { apolloClient } from "../../../vue-apollo";
import { 
  GET_ONE_NOTIFICACION,
  GET_SUCURSALES,
  GET_GERENCIAS,
  GET_SINDICATO,
  GET_MODULES_APP,
  GET_ESTADOS_NOTIFICACIONES_ADMIN,
  PAGINA_LIST_NOTIFICACIONES,
  LIST_PAGINADA_NOTIFICACIONES
} from "@/apollo/queries";
import { 
  SEND_ALERT, 
  EDIT_NOTIFICACION,
  CHANGE_NOTIFICACION_STATUS,
  DELETE_NOTIFICACION,
  RESEND_NOTIFICACION
} from "@/apollo/mutations";
export default {
  namespaced: true,
  state: {
    alertPush_list: [],
    alert_edit: {},
    workplace_list: [],
    area_list: [],
    sindicato_list: [],
    modules_list: [],
    estados: [],
    paginas: []
  },
  getters: {

  },
  mutations: {
    GET_ALERTPUSH_LIST(state, alertPush_resp) {
      // agregar nombre de estado;
      alertPush_resp.sort((a, b) => {
        return (
          new Date(b.fecha_creacion).getTime() -
          new Date(a.fecha_creacion).getTime()
        );
      });
      state.alertPush_list = alertPush_resp;
    },
    CREATE_ALERTPUSH(state, alertPush) {
      state.alertPush_list.unshift(alertPush);
      //console.log("hola, CREATE_ALERTPUSH: ", alertPush);
    },
    EDIT_ALERTPUSH(state, alertPush) {
      //console.log("hola, EDIT_ALERTPUSH: ", alertPush);
      let index = state.alertPush_list.findIndex(
        e => e.id_notificacion == alertPush.id_notificacion
      );
      if (alertPush.id_notificacion && index != -1) {
        state.alertPush_list[index] = alertPush;
      }
    },
    DELETE_ALERTPUSH(state, alertPush){
      let index = state.alertPush_list.findIndex(
        e => e.id_notificacion == alertPush.id_notificacion
      );
      state.alertPush_list.splice(index, 1);
    },
    GET_ALERTA_PUSH_ID(state, data){
      state.alert_edit = data;
    },
    GET_SUCURALES(state, sucursales){
      state.workplace_list = [];
      sucursales.forEach((element) =>  state.workplace_list.push({
        id: element.id_sucursal,
        name: element.nombre_sucursal,
      }));
    },
    GET_AREAS(state, areas){
      state.area_list = [];
      areas.forEach((element) =>  state.area_list.push({
        id: element.id_gerencia,
        name: element.nombre_gerencia,
      }));
    },
    GET_SINDICATOS(state, sindicatos){
      state.sindicato_list = [];
      sindicatos.forEach((element) =>  state.sindicato_list.push({
        id: element.id_sindicato,
        name: element.nombre_sindicato,
      }));
    },
    GET_MODULOS(state, modulos){
      state.modules_list = [];
      state.modules_list.push({
        id: "1",
        name: "General",
      });
      modulos.forEach((element) =>  state.modules_list.push({
        id: element.moduloGeneral.id_modulo,
        name: element.nombre_modulo,
      }));
    },
    SET_ESTADOS_ALERTA(state, estados){
      state.estados = estados;
    },
    SET_PAGINAS_ALERTA(state, data){
      state.paginas = data;
    }
  },
  actions: {

    // Obtener paginacion to alerta push
    async getPaginasAlertas({ commit }, data_){
      // seguir con el paginated
      let params = {
        query: PAGINA_LIST_NOTIFICACIONES,
        variables: data_,
        fetchPolicy: 'network-only'
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.paginasNotificacionesAdminUpdated;
        commit("SET_PAGINAS_ALERTA", response);
      } catch(e) {
        console.log("Falla error, paginasNotificacionesAdminUpdated ", e);
      }
      return response;
    },

    // Obtener data de alertas ya paginadas
    async getListaAlertaPush({ commit }, data_){
      let params = {
        query: LIST_PAGINADA_NOTIFICACIONES,
        variables: data_,
        fetchPolicy: 'network-only'
      }

      let response = null;
      try {
        const res = await apolloClient.query(params);
        if (!res.loading) response = res.data.limitNotificacionesAdmin;
        commit("GET_ALERTPUSH_LIST", response);
      } catch(e) {
        console.log("Falla error, getListaAlertaPush ", e);
      }
      return response;
    },

    async getPushNotifications({ commit, dispatch }, payload) {
			const paginatedNotifications = await dispatch("getPaginasAlertas", payload);
			let ids_notifications = [];
			if (paginatedNotifications) {
				paginatedNotifications.forEach((data) => {
					if (data.pagina == payload.pagina) {
						ids_notifications = data.notificaciones;
					}
				});
			}
      
			const data_ = {
				id_empresa: payload.id_empresa,
				id_usuario: payload.id_usuario,
				id_notificacion: ids_notifications
			};
			const params = {
				query: LIST_PAGINADA_NOTIFICACIONES,
				variables: data_,
				fetchPolicy: 'network-only'
			};

			let response = null;
			try {
				const res = await apolloClient.query(params)
				if (!res.loading) response = res.data.limitNotificacionesAdmin;
        commit("GET_ALERTPUSH_LIST", response);
			} catch (e) {
        console.log("Falla error, getListaAlertaPush ", e);
			}
			return response;
		},

    // Obtener estados de alerta push
    async getEstadosAlertaPush({ commit }, data_){
      let response = {};
      let data = {};
      let params = {
        query: GET_ESTADOS_NOTIFICACIONES_ADMIN,
        variables: data_
      }
      try {
        await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.EstadosPush;
          response = data;
        });
      } catch(e) {
        console.log("listar getEstadosAlertaPush falla: ", e);
        response = null
      }
      return new Promise((resolve)=>{
        if(response  != null){
          commit("SET_ESTADOS_ALERTA", response);
          resolve(response)
        } else {
          resolve(null)
        }
      });
    },

    // Obtener una registro de alerta
    async getAlertaPushByID({ commit }, data_){
      let response = {};
      let data = {};
      let params = {
          query: GET_ONE_NOTIFICACION,
          variables: data_,
          fetchPolicy: 'network-only'
      }
      try {
          await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.GetOneNotificacion;
          response = data;
          //console.log(data);
          });
      } catch(e) {
          console.log("error en getAlertaPushByID falla: ", e);
          response = null;
      }
      return new Promise((resolve)=>{
          if(response  != null){
              commit("GET_ALERTA_PUSH_ID", response);
              resolve(response)
          } else {
              resolve(null)
          }
      });
    },
    // Obtener sucursales
    async getSucursalesAlerta({ commit }, data_){
      let response = {};
      let data = {};
      let params = {
          query: GET_SUCURSALES,
          variables: data_
      }
      try {
          await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.sucursales;
          response = data;
          //console.log(data);
          });
      } catch(e) {
          console.log("error en getSucursalesAlerta falla: ", e);
          response = null;
      }
      return new Promise((resolve)=>{
          if(response  != null){
              commit("GET_SUCURALES", response);
              resolve(response)
          } else {
              resolve(null)
          }
      });
    },
     // Obtener Areas
    async getAreasAlerta({ commit }, data_){
      let response = {};
      let data = {};
      let params = {
          query: GET_GERENCIAS,
          variables: data_
      }
      try {
          await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.gerencias;
          response = data;
          //console.log(data);
          });
      } catch(e) {
          console.log("error en getAreasAlerta falla: ", e);
          response = null;
      }
      return new Promise((resolve)=>{
          if(response  != null){
              commit("GET_AREAS", response);
              resolve(response)
          } else {
              resolve(null)
          }
      });
    },
    // Obtener sindicatos
    async getSindicatosAlerta({ commit }, data_){
      let response = {};
      let data = {};
      let params = {
          query: GET_SINDICATO,
          variables: data_
      }
      try {
          await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.sindicatos;
          response = data;
          //console.log(data);
          });
      } catch(e) {
          console.log("error en getSindicatoAlerta falla: ", e);
          response = null;
      }
      return new Promise((resolve)=>{
          if(response  != null){
              commit("GET_SINDICATOS", response);
              resolve(response)
          } else {
              resolve(null)
          }
      });
    },
    // Obtener módulos de app para alerta push
    async getModulosAlerta({ commit }, data_){
      let response = {};
      let data = {};
      let params = {
          query: GET_MODULES_APP,
          variables: data_
      }
      try {
          await apolloClient.query(params).then(res => {
          if (!res.loading) data = res.data.modulosApp;
          response = data;
          //console.log(data);
          });
      } catch(e) {
          console.log("error en getModulosAlerta falla: ", e);
          response = null;
      }
      return new Promise((resolve)=>{
          if(response  != null){
              commit("GET_MODULOS", response);
              resolve(response)
          } else {
              resolve(null)
          }
      });
    },
    // Crear alerta push
    async createAlertPushAction({ commit }, data_) {
      console.log('creación de alerta push');
      let response = null;
      let data = [];
      let params = {
          mutation: SEND_ALERT,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
            //console.log('createAlertPushAction', res.data.enviarPush);
            if (!res.loading) data = res.data.enviarPush;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo createAlertPushAction: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          resolve(response);
          commit("CREATE_ALERTPUSH", response);
        }
        else {
          resolve(false);
        }
        
      });
    },
    // Editar alerta
    async editAlertPushAction({ commit }, data_) {
      console.log('editar alerta push');
      let response = null;
      let data = [];
      let params = {
          mutation: EDIT_NOTIFICACION,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
            console.log('editAlertPushAction', res.data.editarNotificacionPush);
            if (!res.loading) data = res.data.editarNotificacionPush;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo editAlertPushAction: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          resolve(response);
          commit("EDIT_ALERTPUSH", response);
        }
        else {
          resolve(false);
        }
        
      });
      //commit("EDIT_ALERTPUSH", alertPush);
    },
    // Eliminar alerta
    async deleteAlertPushAction({ commit }, data_) {
      console.log('deleteAlertPushAction de alerta push');
      let response = null;
      let data = [];
      let params = {
          mutation: DELETE_NOTIFICACION,
          variables: data_
      }
      try{
        await apolloClient.mutate(params).then(res => {
            //console.log('createAlertPushAction', res.data.enviarPush);
            if (!res.loading) data = res.data.eliminarNotificacionPush;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo deleteAlertPushAction: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          commit("DELETE_ALERTPUSH", response);
          resolve(response);
        }
        else {
          resolve(false);
        }
        
      });
    },
    // Accion para desactivar o activar alertas
    async cambioEstadoAlerta({ commit }, data_) {
      console.log('cambioEstadoAlerta de alerta push');
      let response = null;
      let data = [];
      let params = {
          mutation: CHANGE_NOTIFICACION_STATUS,
          variables: data_
      }
      try
      {
        await apolloClient.mutate(params).then(res => {
            //console.log('createAlertPushAction', res.data.enviarPush);
            if (!res.loading) data = res.data.changeEstadoNotificacion;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo cambioEstadoAlerta: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          commit("EDIT_ALERTPUSH", response);
          resolve(response);
        }
        else {
          resolve(false);
        }
        
      });
    },
    // Reenvio de alerta push
    async reenviarAlerta({ commit }, data_){
      console.log('reenviarAlerta de alerta push');
      let response = null;
      let data = [];
      let params = {
          mutation: RESEND_NOTIFICACION,
          variables: data_
      }
      try
      {
        await apolloClient.mutate(params).then(res => {
            //console.log('createAlertPushAction', res.data.enviarPush);
            if (!res.loading) data = res.data.reenviarNotificacionPush;
            response = data;
        });
      }
      catch(e){
        console.log("Falla metodo reenviarAlerta: ", e);
      }
      return new Promise((resolve)=>{
        if(response!=null){
          commit("EDIT_ALERTPUSH", response);
          resolve(response);
        }
        else {
          resolve(response);
        }
        
      });
    }
  },
};