import Covid from '@/views/ADMIN/Covid.vue'
import CovidHome from '@/components/Covid/CovidHome.vue'
import CovidUser from '@/components/Covid/CovidUser.vue'
import CovidSymptoms from '@/components/Covid/CovidSymptoms.vue'

export const covidList = {
    path: "/folovid",
    name: "Covid-19",
    component: Covid,
    redirect: { name: "covid-home" },
    children: [{
            path: "home",
            name: "covid-home",
            component: CovidHome
        },
        {
            path: "user",
            name: "covid-user",
            component: CovidUser
        },
        {
            path: "symptoms",
            name: "covid-symptoms",
            component: CovidSymptoms
        },
    ],
};