<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ModalEditPlanInscripciones",
  data() {
    return {
      shown_tramos: [],
      // seleccionar tramo
      checks: [],
      horas: [],
      mins: [],
      horarios: [],
      // Editar hora tramo
      horas_edit: [],
      mins_edit: [],
      horarios_edit: [],
      fechasRenderizadas: new Set(),
      userInputPlan: {
        id_planificacion: null,
        nombre_planificacion: "",
        tramos: [],
        //
        fecha_inicio_ejecucion: "",
        fecha_final_ejecucion: "",
        concurrencia_tiempo: "",
        concurrencia_ejecucion_semanal: "",
        concurrencia_ejecucion_day: "",
        concurrencia_ejecucion_month: "",
      },
      periodo_ini:'',
      periodo_fin:'',
      hora_final_periodo:235959,

      termino_salida_viaje: false,

      tramosUsados: new Set(),
      diasHash: {
        1: "Lunes",
        2: "Martes",
        3: "Miercoles",
        4: "Jueves",
        5: "Viernes",
        6: "Sabado",
        7: "Domingo",
      },
    };
  },
  created() {
    this.setDataForEditingPlan();
    this.toUpdate();
  },
  methods: {
    ...mapActions("transporteModule", ["getTramosEmpresa"]),
    async setDataForEditingPlan() {
      await this.getTramosEmpresa({
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      });
      if (this.editingPlanificacion.id_planificacion == null) return;

      let {
        fecha_inicio_ejecucion,
        fecha_final_ejecucion,
        concurrencia_tiempo,
        concurrencia_ejecucion_semanal,
        concurrencia_ejecucion_day,
        concurrencia_ejecucion_month,
        /**/
        hora_inicio_periodo,
        hora_final_periodo,
        inicio_periodo_day,
        final_periodo_day,
        inicio_periodo_month,
        final_periodo_month,
        inicio_periodo_semanal,
        final_periodo_semanal,
      } = this.editingPlanificacion?.tranpsorte_planificacion_detalle;
      
      this.periodo_ini =  
          (inicio_periodo_day? this.diasHash[inicio_periodo_day]:
          inicio_periodo_month? this.diasHash[inicio_periodo_month]:
          inicio_periodo_semanal? this.diasHash[inicio_periodo_semanal]: '') 
          + ' ' + hora_inicio_periodo;
      this.periodo_fin = 
          (final_periodo_day? this.diasHash[final_periodo_day]: 
          final_periodo_month? this.diasHash[final_periodo_month]: 
          final_periodo_semanal? this.diasHash[final_periodo_semanal]:'')  
          + ' ' + hora_final_periodo;
      
          
      /*
      termino_salida_viaje: 
      Determina si la fecha fin de inscripción es igual a fecha de viaje, es   
      */
      this.termino_salida_viaje = final_periodo_semanal == concurrencia_ejecucion_semanal 
      if(this.termino_salida_viaje){
        this.hora_final_periodo = Number(hora_final_periodo.split(':').join(''))
      }
      //6 1
      
      Object.assign(this.userInputPlan, {
        id_planificacion: this.editingPlanificacion.id_planificacion,
        //detalles
        fecha_inicio_ejecucion,
        fecha_final_ejecucion,
        concurrencia_tiempo,
        concurrencia_ejecucion_semanal,
        concurrencia_ejecucion_day,
        concurrencia_ejecucion_month,
      });

      this.accion = "Editar";
      this.editingPlanificacion.transporte_tramo.forEach((tramo) => {
        this.horas.push(this.getHoraEdit(tramo.hora_viaje));
        this.mins.push(this.getMinEdit(tramo.hora_viaje));
        this.horarios.push(this.gethorarioEdit(tramo.hora_viaje));
        this.horas_edit.push(this.getHoraEdit(tramo.hora_viaje));
        this.mins_edit.push(this.getMinEdit(tramo.hora_viaje));
        this.horarios_edit.push(this.gethorarioEdit(tramo.hora_viaje));
        //para saber los tramos ya usados
        this.tramosUsados.add(tramo.transporte_lista_destino.id_destino);
        //solo para mantener el indice de los checks
        this.checks.push(true);
      });
      this.setTramosForEditing();
    },
    setTramosForEditing() {
      this.userInputPlan.tramos = [];
      this.shown_tramos = [];
      for (let i = 0; i < this.horas.length; i++) {
        let tramo = this.editingPlanificacion.transporte_tramo[i];
        this.shown_tramos.push({
          id_tramo: tramo.id_tramo,
          id_destino: tramo.transporte_lista_destino.id_destino,
          hora_viaje: this.get_hora(
            this.horas[i],
            this.mins[i],
          ),
          fecha_viaje: tramo.fecha_viaje,
          nombre_origen: tramo.transporte_lista_destino.nombre_origen,
          nombre_destino: tramo.transporte_lista_destino.nombre_destino,
          transporte_tipo_medio:
            tramo.transporte_lista_destino.transporte_tipo_medio,
        });
        this.userInputPlan.tramos.push({
          id_tramo: tramo.id_tramo,
          id_destino: tramo.transporte_lista_destino.id_destino,
          hora_viaje: this.get_hora(
            this.horas_edit[i],
            this.mins_edit[i],
          ),
          fecha_viaje: tramo.fecha_viaje,
          index_principal: "-1",
          index_coincidente: "-1",
          tipo_tramo: "1",
        });
      }
      this.tramos_list.forEach((tramo) => {
        if (this.tramosUsados.has(tramo.id_destino)) return;
        const i = this.shown_tramos.length;
        this.shown_tramos.push(tramo);
        this.checks.push(false);
        this.horas.push("01");
        this.mins.push("00");
        this.horarios.push("AM");

        this.horas_edit.push("01");
        this.mins_edit.push("00");
        this.horarios_edit.push("AM");

        this.userInputPlan.tramos.push({
          id_tramo: "0",
          id_destino: tramo.id_destino,
          id_ida_vuelta: "2",
          hora_viaje: this.get_hora(
            this.horas[i],
            this.mins[i],
          ),
          fecha_viaje: "",
          index_principal: "-1",
          index_coincidente: "-1",
          tipo_tramo: "1",
        });
      });
    },
    get_hora(hora, min) {
      if (hora.length == "") hora = "08";
      if (min.length == "") min = "00";
      let hour = parseInt(hora);
      hour = hour.toString();
      if (hour.length == 1) hour = "0" + hour;
      if (min.length == 1) min = "0" + min;
      
      return hour + ":" + min + ":00";
    },
    changeAddedTramosList(i) {
      if (this.checks[i] == true) {
        this.checks[i] = false;
      } else {
        this.checks[i] = true;
      }
      this.agregarTramos(i);
    },
    agregarTramos(i) {
      if (this.checks[i] == true) {
        if (this.horas[i] == "") this.horas[i] = "00";
        if (this.mins[i] == "") this.mins[i] = "00";
        Object.assign(this.userInputPlan.tramos[i], {
          id_destino: this.shown_tramos[i].id_destino,
          id_ida_vuelta: "2",
          hora_viaje: this.get_hora(
            this.horas[i],
            this.mins[i],
          ),
          index_principal: "-1",
          index_coincidente: "-1",
          tipo_tramo: "1",
        });
      }
      this.toUpdate();
    },
    getHoraEdit(hora) {
      let h = hora[0] + hora[1];
      if (h.length == 1) h = "0" + h;
      return h;
    },
    getMinEdit(hora) {
      let m = hora[3] + hora[4];
      if (m.length == 1) m = "0" + m;
      return m;
    },
    gethorarioEdit(hora) {
      let h = hora[0] + hora[1];
      h = parseInt(h);
      if (h > 11) return "PM";
      else return "AM";
    },
    selectHoraTramo(e, i, hora_t, tipo) {

      let key = e.keyCode || e.charCode;
      if (key != 8) {
        //backspace pressedW
        if (this.validateOnlyNumber(hora_t)) {
          if (tipo == "clicked") {
            if (hora_t.length == 1) {
              if (hora_t < 10 && hora_t >= 1) {
                hora_t = "0" + hora_t;
              }

              if (hora_t == "0") {
                hora_t = "00";
              }
            }
            if (hora_t.length > 2 || hora_t > 23) {
              hora_t == "00";
            }
          }
          
          if (!this.editBool) {
            for (let h = 0; h < this.horas.length; h++) {
              if (i == h) this.horas[h] = hora_t;
              break;
            }
            this.agregarTramos(i);
          } else {
            this.selectHoraTramoEdit(i, hora_t);
          }
        } else {
          this.horas[i] = "";
        }
      }
    },
    selectMinTramo(e, i, min_t, tipo) {
      let key = e.keyCode || e.charCode;
      if (key != 8) {
        //backspace pressedW
        if (this.validateOnlyNumber(min_t)) {
          if (tipo == "clicked") {
            if (min_t.length == 1) {
              if (min_t < 10 && min_t >= 1) {
                min_t = "0" + min_t;
              }
              if (min_t == "0") {
                min_t = "00";
              }
            }
            if (min_t.length > 2 || min_t > 23) {
              min_t == "00";
            }
          }
          
          this.mins[i] = min_t;
          this.agregarTramos(i);
          this.selectMinTramoEdit(i, min_t);
          
        } else {
          this.mins[i] = "";
        }
      }
    },
    selectHoraTramoEdit(i, hora) {
      this.horas[i] = hora;
      this.horas_edit[i] = hora;
      this.userInputPlan.tramos[i].hora_viaje = this.get_hora(
        this.horas[i],
        this.mins[i],
      );
    },
    selectMinTramoEdit(i, min) {
      this.mins[i] = min;
      this.mins_edit[i] = min;
      this.userInputPlan.tramos[i].hora_viaje = this.get_hora(
        this.horas[i],
        this.mins[i],
      );
    },
    validateOnlyNumber(text) {
      let num_regex = /^[0-9]*$/g;
      if (!num_regex.test(text)) {
        return false;
      } else {
        return true;
      }
    },
    editValidate(tramos=[]){
      if (
      !this.termino_salida_viaje
      ) return false;
      const data  = tramos.filter(({hora_viaje}) => {
        const [hh,mm] = hora_viaje.split(":");
        return this.validateHour(hh,mm);
      })
      return data.length !== 0;
                      
    },
    toUpdate() {
      const tramosToUpdate = this.checks.reduce((acc, curr, i) => {
        if (!curr) return acc;
        return [...acc, this.userInputPlan.tramos[i]];
      }, []);
      const edit = this.editValidate(tramosToUpdate);
      this.$emit("userInputChange", {
        ...this.userInputPlan,
        tramos: tramosToUpdate,
        edit,
        editText: edit?'Las horas de viaje tienen que ser mayores a la hora de termino de inscripcion, si es el mismo dia.':''
      });
    },
    validateHour(hora,mins){
      let numHour = Number(`${hora}${mins}00`);
      return numHour < this.hora_final_periodo && numHour > 0 && numHour < 235959;
    },
  },
  computed: {
    ...mapState("transporteModule", ["editingPlanificacion", "tramos_list"]),
  },
  beforeUpdate() {
    this.fechasRenderizadas.clear();
  },
  watch: {
    userInputPlan: {
      handler() {
        this.toUpdate();
        this.fechasRenderizadas.clear();
      },
      deep: true,
    },
  },
};
</script>
<template>
  <div class="col-12 d-flex flex-column mx-auto">
    <h6 class="font-main-bold color-main border-bottom pb-1 mb-1">
      Detalles de planificación
    </h6>
    <div class="row">
      <div class="col-6">
        <p>Nombre: {{ editingPlanificacion.nombre_planificacion }}</p>
      </div>
      <div class="col-6">
        <p>
          Día del viaje:
          {{ diasHash[userInputPlan.concurrencia_ejecucion_semanal] || "" }}
        </p>
      </div>
      <div class="col-6" v-show="periodo_ini != ''" >
        <p>Inicio de inscripciones: 
          {{ periodo_ini }}
        </p>
      </div>
      <div class="col-6" v-show="periodo_fin != ''" >
        <p>
          Fin de inscripciones:
          {{ periodo_fin }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6 class="font-main-bold color-main border-bottom pb-1 mb-2">
          Campos editables
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-6">
        <h6 class="font-main text-secondary mt-3 mt-lg-0">
          Cambios de horarios de tramos.
        </h6>
        <div class="card flex-grow-1 scroll-custom-550">
          <div class="card-body">
            <table class="table table-custom table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Tramo</th>
                  <th scope="col">Hora de salida</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(tramo, i) in shown_tramos">
                  <tr
                    :key="tramo.fecha_viaje"
                    :id="tramo.fecha_viaje"
                    v-if="!fechasRenderizadas.has(tramo.fecha_viaje)"
                    class="border-bottom pb-0 mb-0"
                  >
                    <td class="th th-tramo align-top pb-0">
                      <small>
                        <strong>
                          {{ tramo.fecha_viaje }}
                        </strong>
                      </small> 
                    </td>
                    <template v-if="fechasRenderizadas.add(tramo.fecha_viaje)"></template>
                  </tr>
                  <tr v-show="tramosUsados.has(tramo.id_destino)" :key="i">
                    <td class="th th-tramo align-top">
                      <div class="d-flex align-items-top">
                        <div class="flex-grow-1 ms-3">
                          <label
                            :for="i"
                            class="form-check-label w-100 link-cursor"
                          >
                            <p class="text-secondary mb-0">
                              {{ tramo.nombre_origen }} a
                              {{ tramo.nombre_destino }}<br />
                              <small>
                                {{
                                  tramo.transporte_tipo_medio.transporte_tipo
                                    .nombre_transporte
                                }}
                                |
                                {{
                                  tramo.transporte_tipo_medio.cantidad_asiento
                                }}
                                asientos
                              </small>
                            </p>
                          </label>
                        </div>
                      </div>
                    </td>
                    <td class="th th-hora-salida w-170"
                    
                    >
                      <div class="d-flex"
                      :class="{
                        'rounded border border-warning': termino_salida_viaje ?
                        validateHour(horas[i],mins[i])
                        :false 
                      }"
                    >
                        <div class="flex-grow-1">
                          <input
                            class="form-control input-custom"
                            type="number"
                            v-model="horas[i]"
                            min="0"
                            max="23"
                            @keyup="selectHoraTramo($event, i, horas[i], '')"
                            @click="
                              selectHoraTramo($event, i, horas[i], 'clicked')
                            "
                          />
                        </div>
                        <div
                          class="d-flex justify-content-center align-items-center px-2"
                        >
                          :
                        </div>
                        <div class="flex-grow-1">
                          <input
                            class="form-control input-custom"
                            type="number"
                            v-model="mins[i]"
                            min="0"
                            max="59"
                            @keyup="selectMinTramo($event, i, mins[i], '')"
                            @click="
                              selectMinTramo($event, i, mins[i], 'clicked')
                            "
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 mt-3 mt-xl-0">
        <h6 class="font-main text-secondary mt-3 mt-lg-0">
          Seleccionar tramos para añadir a la planificación.
        </h6>
        <div class="card flex-grow-1 scroll-custom-550">
          <div class="card-body">
            <table class="table table-custom table-custom-responsive mb-0">
              <thead>
                <tr>
                  <th scope="col">Tramo</th>
                  <th scope="col">Hora de salida</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(tramo, i) in shown_tramos">
                  <tr
                    v-show="!tramosUsados.has(tramo.id_destino)"
                    :key="'edit-' + i"
                  >
                    <td class="th th-tramo align-top">
                      <div class="d-flex align-items-top">
                        <div class="flex-shrink-0">
                          <input
                            :id="i"
                            class="form-check-input me-1"
                            type="checkbox"
                            value="tramo"
                            @change="changeAddedTramosList(i, tramo)"
                          />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <label
                            :for="i"
                            class="form-check-label w-100 link-cursor"
                          >
                            <p class="text-secondary mb-0">
                              {{ tramo.nombre_origen }} a
                              {{ tramo.nombre_destino }}<br />
                              <small>
                                {{
                                  tramo.transporte_tipo_medio.transporte_tipo
                                    .nombre_transporte
                                }}
                                |
                                {{
                                  tramo.transporte_tipo_medio.cantidad_asiento
                                }}
                                asientos
                              </small>
                            </p>
                          </label>
                        </div>
                      </div>
                    </td>
                    <td class="th th-hora-salida w-170">
                      <div class="d-flex" 
                        :class="{
                          'rounded border border-warning': termino_salida_viaje ?
                          checks[i]?
                          validateHour(horas[i],mins[i]) : false :false 
                        }">
                        <div class="flex-grow-1">
                          <input
                            class="form-control input-custom"
                            type="number"
                            v-model="horas[i]"
                            min="0"
                            max="23"
                            @keyup="selectHoraTramo($event, i, horas[i], '')"
                            @click="
                              selectHoraTramo($event, i, horas[i], 'clicked')
                            "
                          />
                        </div>
                        <div
                          class="d-flex justify-content-center align-items-center px-2"
                        >
                          :
                        </div>
                        <div class="flex-grow-1">
                          <input
                            class="form-control input-custom"
                            type="number"
                            v-model="mins[i]"
                            min="0"
                            max="59"
                            @keyup="selectMinTramo($event, i, mins[i], '')"
                            @click="
                              selectMinTramo($event, i, mins[i], 'clicked')
                            "
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
