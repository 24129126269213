<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-secondary fw-bold">Layout logotipo</h5>
      <button
        type="button"
        class="modal-custom-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <font-awesome-icon icon="xmark" />
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-info text-center">
            <small
              >Formato PNG | Dimensiones 500x500 px | Tamaño máximo 3 MB</small
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <UploadImg
            v-if="img_preview_src === ''"
            @sendImg="handleLogoSelect"
            :viewport="viewport"
            :acceptedFormats="['image/png']"
            :maxSizeMB="3"
            style="height: 300px; width: 300px; margin: auto"
            :dimensions="{ height: 500, width: 500 }"
          />
          <div v-if="img_preview_src !== ''" class="upload-image">
            <div class="upload-image-preview">
              <button
                @click="deletePreview()"
                class="upload-image-preview-remove"
              >
                <font-awesome-icon icon="times" />
              </button>
              <img class="upload-image-preview-img" :src="img_preview_src" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        ref="closeModal"
        class="btn btn-custom-color-white rounded-pill mw-100"
        data-bs-dismiss="modal"
      >
        <i class="fa-solid fa-angle-left pe-1"></i>
        Volver
      </button>
      <button
        @click="setLogo()"
        type="button"
        class="btn btn-custom-color-blue rounded-pill mw-100"
      >
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UploadImg from "../UploadImg.vue";

export default {
  components: {
    UploadImg,
  },

  props: {
    saved_logo_src: String,
  },

  data() {
    return {
      logo_img: {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      },
      img_preview_src: "",
      viewport: { width: 300, height: 300 },
    };
  },

  watch: {
    saved_logo_src() {
      if (this.$route.name.includes("edit")) {
        this.img_preview_src = this.saved_logo_src;
      } else {
        this.img_preview_src = this.logo_img.base64;
      }
    },
  },

  mounted() {
    this.img_preview_src =
      this.channelLayout.logo.base64 ?? this.logo_img.base64;
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),
  },

  methods: {
    ...mapActions("tvCorporativaModule", [
      "setChannelLayoutLogo",
      "deleteChannelLayoutLogo",
    ]),

    setLogo() {
      this.setChannelLayoutLogo(this.logo_img);
      this.$emit("closeLogo");
      this.$refs.closeModal.click();
    },

    handleLogoSelect(img) {
      this.logo_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
      };
      // TODO: controlar formato, dimensiones y tamaño
    },
    deletePreview() {
      this.deleteChannelLayoutLogo();
      this.logo_img = {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      };
      this.img_preview_src = this.logo_img.base64;
    },
  },
};
</script>
