import News from '@/views/ADMIN/News.vue'
import NewsList from '@/components/News/List.vue'
import NewsUserList from '@/components/News/UserList.vue'
import NewsAdd from '@/components/News/Add.vue'
import NewsAddCat from '@/components/News/AddCategories.vue'

export const newsList = {
  path: "/noticias",
  name: "Noticias",
  component: News,
  redirect: { name: "news-list" },
  children: [ {
      path: "list",
      name: "news-list",
      component: NewsList
    }, {
      path: "userlist",
      name: "intra-news-list",
      component: NewsUserList
    }, {
      path: "add",
      name: "news-add",
      component: NewsAdd
    }, {
      path: "edit/:id",
      name: "news-edit",
      component: NewsAdd
    }, {
      path: "add-categories/",
      name: "news-categories",
      component: NewsAddCat
    },
  ],
};


export const intraNewsList =  {
  path: "/intra-noticias",
  name: "IntraNoticias",
  component: NewsUserList
};