import Organigrama from '@/views/ADMIN/Organigrama.vue'
import OrgList from '@/components/Organigrama/List.vue'
import OrgAdd from '@/components/Organigrama/Add.vue'
import OrgEmpresas from '@/components/Organigrama/OrganigramaEmpresas.vue'

export const organigramaList = {
  path: "/organigrama",
  name: "Organigrama",
  component: Organigrama,
  redirect: { name: "org-list" },
  children: [ {
      path: "list",
      name: "org-list",
      component: OrgList
    }, {
      path: "add",
      name: "org-add",
      component: OrgAdd
    }, {
      path: "edit/:id",
      name: "org-edit",
      props: true,
      component: OrgAdd
    }, {
      path: "empresas",
      name: "org-empresas",
      component: OrgEmpresas
    },
  ],
};