import Library from '@/views/ADMIN/Library.vue'
import LibraryList from '@/components/Library/List.vue'
import LibraryAdd from '@/components/Library/Add.vue'
import LibraryAddCat from '@/components/Library/AddCategories.vue'

export const libraryList = {
  path: "/biblioteca",
  name: "Biblioteca",
  component: Library,
  redirect: { name: "library-list" },
  children: [ {
      path: "list",
      name: "library-list",
      component: LibraryList
    }, {
      path: "add",
      name: "library-add",
      component: LibraryAdd
    }, {
      path: "edit/:id",
      name: "library-edit",
      component: LibraryAdd
    }, {
      path: "add-categories",
      name: "library-add-categories",
      component: LibraryAddCat
    },
  ],
};