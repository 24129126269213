<template>
  <div id="form-inscripciones-add">
    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700 px-4">

        <div class="row mb-3">
          <div class="col-12">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img 
                  class="inscripciones-add__user-img" 
                  :src="handleUserImage(usuario.imagen_perfil)"
                />
              </div>
              <div class="flex-grow-1 d-flex align-items-center ms-3">
                <h6 class="font-main-bold mb-0">
                  {{ usuario.primer_nombre | capitalize }} 
                  {{ usuario.apellido_paterno | capitalize }}
                  
                  <br>
                  <span class="text-secondary">
                    {{ usuario.cargo[0] != undefined ? usuario.cargo[0].nombre_cargo:''}} 
                  </span><br>
                  <small class="text-secondary">{{usuario?.turno[0]?.nombre_turno }}</small>
                </h6>
              </div>
            </div>            
          </div>
        </div>

        <div class="row flex-grow-1">
          <div class="col-12 col-lg-6 d-flex flex-column">
            <h6 class="font-title-bold" :class=" !solicita_transporte ? 'color-main' : '' ">
              Selecciona tu información
            </h6>
            <div class="card flex-grow-1" :class=" !solicita_transporte ? 'border-primary' : '' ">
              <div class="card-body">
                <div class="mb-3">
                  <label class="text-secondary">Fecha en la que deseas inscribir el viaje</label>
                  <v-select 
                    class="selvue-custom"
                    :placeholder="'Selecciona la fecha'" 
                    :options="planes_list" 
                    label="nombre_planificacion"
                    v-model="periodo"
                    @input="selectPeriodo(periodo)" 
                    :clearable="false" 
                    :searchable="false"
                  >
                    <span slot="no-options"></span>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="solicita_transporte" class="col-12 col-lg-6 d-flex flex-column">
            <h6 class="font-title-bold color-main">
              Solicita tu transporte
            </h6>
            <div class="card flex-grow-1 border-primary">
              <div class="card-body">
                <h6 class="font-main border-bottom mb-2 pb-1">
                  Viaje de ida
                </h6>   
                <div>
                  <label class="text-secondary">Selecciona un tramo</label>
                  <v-select 
                    class="selvue-custom"
                    :placeholder="'Tramos disponibles'" 
                    :options="tramosApp_list" 
                    label="tramo"
                    :disabled="disable_right_side"
                    v-model="tramoIda" 
                    @input="selectTramoIda(tramoIda)"
                    :clearable="false"
                    :searchable="false"
                  >
                    <span slot="no-options"></span>
                  </v-select>
                </div>
                <div v-if="hora_tramoIda_seleccionado !== ''" class="mt-3">
                  <div class="alert alert-light border d-flex justify-content-between align-items-center" style="height: 60px;">
                    <span class="text-secondary">
                      <i class="fa-solid fa-circle-arrow-right color-green"></i>
                      Salida: {{hora_tramoIda_seleccionado+' hrs.'}}
                    </span>
                    <span>
                      <div 
                        v-if="show_ida_vuelta_radio"
                        class="form-check form-switch form-switch-custom form-switch-custom-reverse"
                      >
                        <input 
                          class="form-check-input"
                          type="checkbox" 
                          id="switch-ida-vuelta"
                          v-model="ida_vuelta_radio" 
                        >
                        <label
                          class="form-check-label link-cursor" 
                          for="switch-ida-vuelta"
                        >
                          <i class="fa-solid fa-arrow-right-arrow-left pe-1"></i>
                          Ida y vuelta
                        </label>
                      </div>
                    </span>
                  </div>
                </div>

                <div v-if="tramoIda !== '' " class="accordion accordion-custom mt-3">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button 
                        class="accordion-button collapsed" 
                        type="button"
                        data-bs-toggle="collapse" 
                        data-bs-target="#collapse-asiento-ida" 
                        aria-expanded="false"
                      >
                      <span class="d-flex justify-content-between w-100">
                        <span> Elige un asiento </span>
                        <span v-show="selectedAsientoIda.nombre_asiento" class="badge bg-success rounded-pill me-2">
                          {{ selectedAsientoIda.nombre_asiento }}
                        </span>
                      </span>
                      </button>
                    </h2>

                    <div id="collapse-asiento-ida" class="accordion-collapse collapse">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12 form-nums">
                            <DistribucionAsientos 
                              :asientos="asientosIda" 
                              :editable="true"
                              @clickAsiento="selectAsiento('ida', ...arguments);" 
                              key="ida"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div v-if="ida_vuelta_radio" class="row mt-3">
                  <div class="col-12">
                    <h6  class="font-main border-bottom mb-2 pb-1">
                      Viaje de vuelta
                    </h6>  

                    <div>
                      <label class="text-secondary">Selecciona un tramo</label>
                      <v-select 
                        class="selvue-custom"
                        :placeholder="'Tramos disponibles'" 
                        :options="tramosAppVuelta_list" 
                        label="tramo"
                        v-model="tramoVuelta" 
                        @input="selectTramoVuelta(tramoVuelta)" 
                        :clearable="false"
                        :searchable="false"
                      >
                        <span slot="no-options"></span>
                      </v-select>  
                      <div v-if="hora_tramoVuelta_seleccionado !== ''" class="mb-3">
                        <div class="alert alert-light border mt-3">
                          <i class="fa-solid fa-circle-arrow-left color-red"></i>
                          Salida: {{hora_tramoVuelta_seleccionado + ' hrs.'}}
                        </div>
                      </div>
                    </div>

                    <div v-if="tramoVuelta !== '' " class="accordion accordion-custom mt-3">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button 
                            class="accordion-button collapsed" 
                            type="button"
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapse-asiento-vuelta" 
                            aria-expanded="false" 
                          >
                          <span class="d-flex justify-content-between w-100">
                            <span> Elige un asiento </span>
                              <span 
                                v-show="selectedAsientoVuelta.nombre_asiento"
                                class="badge bg-danger rounded-pill me-2">
                                {{ selectedAsientoVuelta.nombre_asiento }}
                              </span>
                          </span>
                          </button>
                        </h2>

                        <div id="collapse-asiento-vuelta" class="accordion-collapse collapse">
                          <div class="accordion-body">
                            <div class="row">
                              <div class="col-12 form-nums">
                                <DistribucionAsientos 
                                  :asientos="asientosVuelta" 
                                  :editable="true"
                                  @clickAsiento="selectAsiento('vuelta', ...arguments);" 
                                  key="vuelta"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button 
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="cancel()">
                Volver
              </button>
              <button 
                class="btn btn-custom-color-blue border-round-50 mw-100" 
                :disabled="validateButtonSend()"
                @click="addOrEditInscripcion()"
              >
                {{ nameAction() }}
              </button>
            </div>
          </div>
        </div>

        <Question 
          v-if="open_question_modal" 
          :msg="question_modal_msg" 
          :hideCancel="false" 
          @cancel="cancelQuestion"
          @accept="acceptQuestion"
        />
        <Spinner v-if="show_spinner"/>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import Question from "../Modales/Question.vue";
import Spinner from "../Spinner.vue";
import DistribucionAsientos from "../DistribucionAsientos.vue"

export default {
  components: {
    Question,
    Spinner,
    DistribucionAsientos
  },

  data() {
    return {
      show_ida_vuelta_radio: false,
      ida_vuelta_radio: false,
      is_request_edit: false,
      disable_right_side: true,
      show_spinner: false,
      user: this.$ls.get("user"),
      user_default_img: require("@/assets/img/user_default_img.svg"),
      usuario: {
        apellido_materno: '',
        apellido_paterno: '',
        cargo: [{
          id_cargo: "",
          nombre_cargo: ""
        }],
        correo: '',
        dv_usuario:'',
        gerencia: [{
          bussiness_unit: "",
          id_gerencia: "",
          nombre_gerencia: "",
          unit_number: "",
        }],
        id_usuario: "",
        imagen_perfil: '',
        primer_nombre: '',
        rut_usuario: '',
        segundo_nombre: '',
        telefono_fijo: '',
        telefono_movil: ''
      },
      transporte: "Terrestre",
      id_tipoTransporte: "1",

      tramoIda: "",
      tramoVuelta: "",
      id_tramoIda: "",
      id_tramoVuelta: "",

      id_planificacion: "",
      fecha_viaje: "",

      hora_tramoIda_seleccionado:'',
      hora_tramoVuelta_seleccionado:'',
      
      asientosIda: [],
      asientosVuelta: [],

      solicita_transporte: false,

      open_question_modal: false,
      question_modal_msg: "",


      selectedAsientoIda: {},
      selectedAsientoVuelta: {},

      periodo: "",
      turno: "",
      team: "",
      id_turno: "",
      id_team: "",

      id_empresa: this.$ls.get("user").empresa[0].id_empresa,

      goback_totramos: false
    };
  },

  computed: {
    ...mapState("transporteModule", [
      "planes_list",
      "tramosApp_list",
      "idaVuelta_list",
      "tramosAppVuelta_list",
      "solicitudes_list",
      "solicitudesArr",
    ]),

    left_side_inputs() {
      return {
        fecha_viaje: this.fecha_viaje,
        turno: this.turno,
        team: this.team
      }
    }
  },

  watch: {
    left_side_inputs(val) {
      if (val.fecha_viaje && val.turno) {
        this.solicita_transporte = true;
        this.disable_right_side = false;
      } else {
        this.solicita_transporte = false;
        this.disable_right_side = true;
      }
    },
  },

  async created() {
    this.show_spinner = true;    
    // planificaciones
    let empresa = { idEmpresa: this.id_empresa };
    // solicitudes
    if (this.$route.name == 'transporte-inscripciones-add') { 
      let sol_found = this.solicitudesArr.find(solicitud => solicitud.usuario.id_usuario == this.$route.params.id)
      if (sol_found != undefined) {
        this.usuario = sol_found.usuario
      }
      
      if (this.solicitudesArr.length === 0) { // en caso de refrescar la página
        this.usuario = await this.getUserInfo();
      }
      this.getPlanificacionesApp(empresa)
    } else if (this.$route.name == 'transporte-inscripciones-edit') {
      if (this.$route.params.from != undefined && this.$route.params.from == 'tramos') {
        this.goback_totramos = true;
      } else {
        this.goback_totramos = false;
      }
      this.is_request_edit = true;
      this.disable_right_side = false;
      let solicitud = {
        ...empresa,
        idUsuario: this.$route.params.id,
      };
      await Promise.all([
        this.getPlanificacionesApp(empresa),
        this.getFindSolicitudes(solicitud),
      ]);
      if(this.solicitudes_list != undefined)
      this.llenar(this.solicitudes_list);
    }
     
    const turno = this.usuario?.turno?.[0];
    const team = this.usuario?.team;
    
    if (turno) {
      this.turno = turno.nombre_turno;
      this.id_turno = turno.id_turno;
    }
    if (team) {
      this.team = team.nombre_team;
      this.id_team = team.id_team;
    }
     
    this.show_spinner = false;
  },

  async mounted() {
    this.periodo = "";
    await this.resetPlanificacionesList();
    this.clearFields();
  },

  methods: {
    ...mapActions("transporteModule", [
      "getPlanificacionesApp",
      "getTramosApp",
      "getIdaVuelta",
      "getTramosAppVuelta",
      "getFindSolicitudes",
      'addSolicitudesPaginated',
      'editSolicitudesPaginated',
      'resetTramosList',
      'resetPlanificacionesList',
    ]),

    ...mapActions("usersModule", ["getUserAction"]),

    async getUserInfo() {
      const data_ = {
        id_usuario: this.$route.params.id,
        id_empresa: this.id_empresa
      };
      const res = await this.getUserAction(data_);

      if (!res) this.toastGenericError();
      return res;
    },

    async clearFields() {
      await this.resetTramosList();
      this.tramoIda = "";
      this.tramoVuelta = "";
      this.show_ida_vuelta_radio = false;
      this.ida_vuelta_radio = false;
      this.disable_right_side = false;
      this.asientosIda = [];
      this.asientosVuelta = [];
      this.hora_tramoIda_seleccionado = '';
      this.hora_tramoVuelta_seleccionado = '';
    },

    nameAction() {
      if (this.is_request_edit) return "Guardar";
      else return "Añadir";
    },
    validateButtonSend() {
      if (this.tramoIda == "" || this.selectedAsientoIda.nombre_asiento == "") return true;
      else return false;
    },
    async llenar(data) {
      // 1- periodo
      if (data.length > 0) {
        const fechaTransporte = data[0].transporte_tramo.fecha_viaje;
        const planificacionId = data[0].transporte_tramo.transporte_planificacion.id_planificacion
        this.periodo = this.obtenerFecha(fechaTransporte);
        //const planActual = this.planes_list.find( plan => plan.id_planificacion == planificacionId )

        this.fecha_viaje = fechaTransporte;
        // turno
        this.turno = data[0].usuario.turno[0].nombre_turno;
        this.id_turno = data[0].usuario.turno[0].id_turno;
        // team
        this.team = data[0].usuario.team.nombre_team;
        this.id_team = data[0].usuario.team.id_team;
        this.id_tipoTransporte = data[0].transporte_tramo.transporte_lista_destino.transporte_tipo_medio.transporte_tipo.id_tipo;
        this.solicita_transporte = true;
        // 5- tramo
        this.tramoIda = data[0].tramo;
        this.id_tramoIda = data[0].transporte_tramo.id_tramo;
        this.id_planificacion = planificacionId;
        this.usuario = data[0].usuario;
         //busqueda de asientosIda
        let asiento_ida = {
          idEmpresa: this.id_empresa,
          idPlanificacion:this.id_planificacion,
          fechaViaje: this.fecha_viaje,
          idTipoTransporte: this.id_tipoTransporte,
          isPrincipal: "1",
          idTramoPadre: "0",
        };
        await this.getTramosApp(asiento_ida);
        let tramo_index = this.tramosApp_list.findIndex(tramo => tramo.id_tramo == this.id_tramoIda);
        
        if (tramo_index != -1) {
          await this.selectTramoIda(this.tramosApp_list[tramo_index]);
          
          //this.asientosIda = this.tramosApp_list[tramo_index].asientosIda || [];
          //this.hora_tramoIda_seleccionado = this.tramosApp_list[tramo_index].hora_viaje;
          
          let index_seat = this.asientosIda.findIndex(seat => (seat.nombre_asiento === data[0].transporte_asiento.nombre_asiento))
          console.log("asientosIda", this.asientosIda[0].nombre_asiento === data[0].transporte_asiento.nombre_asiento);//Todo: revisar
          console.log("asientosIda", this.asientosIda[0].nombre_asiento);//Todo: revisar
          console.log("index_seat", index_seat);//Todo: revisar
          
          this.asientosIda.forEach(seat => { seat.selected = false; });
          if (index_seat >= 0) {
            this.asientosIda[index_seat].disponible = false;
            this.asientosIda[index_seat].selected = true;
            this.selectAsiento('ida', this.asientosIda[index_seat]);
          }
        }
         
        if (data.length > 1) {
          this.show_ida_vuelta_radio = true;
          this.ida_vuelta_radio = true;
          this.id_tramoVuelta = data[1].transporte_tramo.id_tramo;
          let asiento_vuelta = {
            idEmpresa: this.id_empresa,
            idPlanificacion: this.id_planificacion,
            fechaViaje: this.fecha_viaje,
            idTipoTransporte: this.id_tipoTransporte,
            idTramoIda: this.id_tramoIda,
          };
          await this.getTramosAppVuelta(asiento_vuelta);
           
          let tramo_vuelta_index = this.tramosAppVuelta_list.findIndex(tramo => tramo.id_tramo == data[1].transporte_tramo.id_tramo);
          if (tramo_vuelta_index != -1) {
            this.asientosVuelta = this.tramosAppVuelta_list[tramo_vuelta_index].asientosIda;
            this.hora_tramoVuelta_seleccionado = this.tramosAppVuelta_list[tramo_vuelta_index].hora_viaje
            this.asientosVuelta.forEach(seat => {
              seat.selected = false;
            })
            this.tramoVuelta = this.tramosAppVuelta_list[tramo_vuelta_index];
            let index_seat_vuelta = this.asientosVuelta.findIndex(seat => (
             seat.nombre_asiento === data[1].transporte_asiento.nombre_asiento
            ))
            if (index_seat_vuelta != -1) {
              this.asientosVuelta[index_seat_vuelta].disponible = false;
              this.asientosVuelta[index_seat_vuelta].selected = true;
              this.selectAsiento('vuelta', this.asientosVuelta[index_seat_vuelta])
              this.selectedAsientoVuelta.nombre_asiento = this.asientosIda[index_seat_vuelta].nombre_asiento
            }
          }
        }
      }
    },

    handleUserImage(imagen_perfil) {
      if (
        imagen_perfil !== null &&
        imagen_perfil !== "" &&
        imagen_perfil !== undefined
      ) {
        return imagen_perfil;
      } else {
        return this.user_default_img;
      }
    },

    // periodo
    async selectPeriodo(plan) {
      this.clearFields()
      this.periodo = plan.nombre_planificacion; /* this.obtenerFecha(plan.fecha_viaje) */
      this.id_planificacion = plan.id_planificacion;
      this.fecha_viaje = plan.fecha_viaje;
      this.solicita_transporte = false;
      this.tramoVuelta = "";
      this.selectedAsientoIda = {};
      this.asientoVuelta = {};
      let asiento = {
        idEmpresa: this.id_empresa,
        idPlanificacion: this.id_planificacion,
        fechaViaje: this.fecha_viaje,
        idTipoTransporte: this.id_tipoTransporte,
        isPrincipal: "1",
        idTramoPadre: "0",
      };
      await this.getTramosApp(asiento);
    },


    async selectTramoIda(tramo) {
      this.selectedAsientoIda = {};
      this.id_tramoIda = tramo.id_tramo;
      this.asientosIda = tramo.asientos;
      this.hora_tramoIda_seleccionado = tramo.hora_viaje;
      await this.getListaTramosVuelta();
    },

    selectTramoVuelta(tramo) {
      this.selectedAsientoVuelta = {};
      this.id_tramoVuelta = tramo.id_tramo;
      this.asientosVuelta = tramo.asientos;
      this.hora_tramoVuelta_seleccionado = tramo.hora_viaje;
    },

    selectAsiento(tipo, asiento) {
      if (tipo == "ida") {
        this.asientosIda.forEach(seat => {
          if (seat.disponible) {
            seat.selected = false;
          } else {
            if (seat.selected) {
              seat.selected = false;
              seat.disponible = true;
            }
          }
        });
        const index = this.asientosIda.findIndex(t => t.id_asiento === asiento.id_asiento);
        if (!this.asientosIda[index].disponible) return;
        this.asientosIda[index].selected = true;
        this.selectedAsientoIda = {
          nombre_asiento: asiento.nombre_asiento,
          id_tramo: this.id_tramoIda,
          id_asiento: asiento.id_asiento,
          id_tramo_conexion: null,
          id_asiento_conexion: null,
          id_ida_vuelta: "1",
        };
      } else if (tipo == "vuelta") {
        this.asientosVuelta.forEach(seat => {
          if (seat.disponible) {
            seat.selected = false;
          } else {
            if (seat.selected) {
              seat.selected = false;
              seat.disponible = true;
            }
          }
        });
        const index = this.asientosVuelta.findIndex(t => t.id_asiento === asiento.id_asiento);
        if (!this.asientosVuelta[index].disponible) return;
        this.asientosVuelta[index].selected = true;
        this.selectedAsientoVuelta = {
          nombre_asiento: asiento.nombre_asiento,
          id_tramo: this.id_tramoVuelta,
          id_asiento: asiento.id_asiento,
          id_tramo_conexion: null,
          id_asiento_conexion: null,
          id_ida_vuelta: "2",
        };
      }
    },

    async cancel() {
      this.periodo = "";
      await this.resetPlanificacionesList();
      this.clearFields();
      this.asientosIda.forEach(seat => { seat.selected = false; });
      this.asientosVuelta.forEach(seat => { seat.selected = false; });
      if(this.goback_totramos){
        this.$router.push({ name: "transporte-en_curso" });
      }else{
        this.$router.push({ path: "/transporte/inscripciones/list" });
      }
    },

    addOrEditInscripcion() {
      if (!this.is_request_edit) {
        if (this.comprobar()) {
          this.acceptQuestion();
        }
      } else {
        if (this.comprobar()) {
          this.acceptQuestionEdit();
        }
      }
    },

    cancelQuestion() {
      this.open_question_modal = false;
    },

    comprobar() {
      return (
        Object.keys(this.selectedAsientoIda).length != 0 &&
        this.id_turno != "" &&
        this.id_team != ""
      );
    },

    async acceptQuestion() {
      this.show_spinner = true;

      const selectedAsientoIdaWithoutNombre = Object.assign({}, this.selectedAsientoIda);
      delete selectedAsientoIdaWithoutNombre.nombre_asiento;
      const tramos = [selectedAsientoIdaWithoutNombre];

      if (this.selectedAsientoVuelta.nombre_asiento) {
        const selectedAsientoVueltaWithoutNombre = Object.assign({}, this.selectedAsientoVuelta);
        delete selectedAsientoVueltaWithoutNombre.nombre_asiento;
        tramos.push(selectedAsientoVueltaWithoutNombre);
      }
      const data = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.usuario.id_usuario,
        tramos: tramos,
        idTurno: this.id_turno,
        idTeam: this.id_team,
      };
      try {
        await this.addSolicitudesPaginated(data);
        this.toastSolicitudCreateSuccess();
        this.$router.push({ name: "transporte-inscripciones-list" });
      } catch (error) {
        this.toastSolicitudCreateError();
      }
      this.show_spinner = false;
    },

    async acceptQuestionEdit() {
      this.show_spinner = true;
      
      const selectedAsientoIdaWithoutNombre = Object.assign({}, this.selectedAsientoIda);
      delete selectedAsientoIdaWithoutNombre.nombre_asiento;
      const tramos = [selectedAsientoIdaWithoutNombre];

      if (this.selectedAsientoVuelta.nombre_asiento) {
        const selectedAsientoVueltaWithoutNombre = Object.assign({}, this.selectedAsientoVuelta);
        delete selectedAsientoVueltaWithoutNombre.nombre_asiento;
        tramos.push(selectedAsientoVueltaWithoutNombre);
      }

      const data = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.usuario.id_usuario,
        tramos: tramos,
        idTurno: this.id_turno,
        idTeam: this.id_team,
        idCreador: "1",
        idSolicitud: this.solicitudes_list.map(s => s.id_solicitud),
      };
      try {
        await this.editSolicitudesPaginated(data);
        this.toastSolicitudEditSuccess();
        this.$router.push({ name: "transporte-inscripciones-list" });
      } catch (error) {
        this.toastSolicitudEditError();
      }
      this.show_spinner = false;
    },

    async getListaTramosVuelta() {
      const data = {
        idEmpresa: this.id_empresa,
        idPlanificacion: this.id_planificacion,
        fechaViaje: this.fecha_viaje,
        idTipoTransporte: this.id_tipoTransporte,
        idTramoIda: this.id_tramoIda,
      };
      await this.getTramosAppVuelta(data);
      if (this.tramosAppVuelta_list.length > 0) {
        this.show_ida_vuelta_radio = true;
      } else {
        this.show_ida_vuelta_radio = false;
      }
    },
    obtenerFecha(fecha) {
      let semana = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      fecha = new Date(fecha + "T00:00:00");
      let diaSemana = fecha.getDay();
      let dia = fecha.getDate();
      let mes = fecha.getMonth();
      return `${semana[diaSemana]} ${dia} de ${meses[mes]}`;
    },

    toastSolicitudCreateSuccess() {
      this.$toast.open({
        message: "Tu solicitud ha sido realizada correctamente.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastSolicitudCreateError() {
      this.$toast.open({
        message: "Ocurrió un problema al agregar la solicitud. Por favor intenta nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastSolicitudEditSuccess() {
      this.$toast.open({
        message: "Tu solicitud ha sido editada correctamente.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastSolicitudEditError() {
      this.$toast.open({
        message: "Error al editar la solicitud. Por favor intenta nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      }); 
    }
  },
  //FF
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>
