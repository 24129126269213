<template>

  <section id="add-banner-muro-digital">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 my-3">
          Selecciona los banners que quieres añadir a la lista de reproducción.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button 
          @click="addBannerModal" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
        >
          <font-awesome-icon 
            icon="plus" 
            class="pe-2"
          />
            Cargar banner
        </button>
      </div>
    </div> 

    <div class="row mb-3">
      <div class="col-12 input-search">
        <input 
          type="search" 
          class="form-control input-custom"
          placeholder="Ingresa nombre del banner"
          v-model="title"
          @keypress.enter="onBannerSearch()"
        />
          <span class="input-search-icon">
            <font-awesome-icon icon="search"/>
          </span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div 
          id="card-container" 
          class="card card-custom border-round-10 card-shadow border-0"
        >
          <div class="card-body">

            <div class="row mb-3">
              <div class="co-12">
                <h5 class="card-title font-title-bold color-main"> Listado de banners </h5>
                <p class="text-secondary mb-0">
                  Posiciónate sobre un banner y selecciona entre previsualizar, añadir o eliminar.
                </p>
              </div>
            </div>

            <BannerContainer 
              :banners="banners" 
              :pagination="pagination" 
              @playlistChange="onPlaylistChange"
              @bannerDeleted="onBannerListChange" 
            />

            <div class="row mt-3">
              <div class="col-12 d-flex flex-column flex-lg-row justify-content-between align-lg-items-center">
                <div
                  :style="{ opacity: active_time ? 1 : 0.5 }"
                  class="card mb-3 mb-lg-0"
                >
                  <div class="card-body p-2">
                    <p class="mb-0 text-center text-lg-start d-flex align-items-center">
                      <span class="me-2">
                        Duración de cada imagen
                      </span>
                      <span class="d-flex align-items-center justify-content-end">
                        <button 
                          @click="removeTime()"
                          :disabled="!active_time"
                          class="btn btn-custom-color-white rounded-circle border"
                          type="button"
                        >
                          <font-awesome-icon icon="minus"/>
                        </button>
                        <span class="border rounded-2 py-1 px-2 mx-2 mw-100 text-center">{{ counter }} s</span>
                        <button 
                          @click="addTime()"
                          :disabled="!active_time"
                          class="btn btn-custom-color-white rounded-circle border"
                          type="button"
                        >
                          <font-awesome-icon icon="plus"/>
                        </button>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <button
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                    @click="$router.go(-1)"
                  >
                    Volver
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    @click="addBannerListToChannel"
                    :disabled="!active_button"
                  >
                    Guardar
                  </button>
                </div> 
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div 
      v-show="add_modal == true" 
      class="add-banner__modal"
    >
      <CargarBannerModal 
        @closeModal="closeModal" 
        @bannerUploaded="onBannerUpload"
        @showSpinner="show_spinner = true"
        @hideSpinner="show_spinner = false"
      />
    </div>
    <Spinner v-if="show_spinner"/>
  </section>

</template>
  
<script>
import { mapState, mapActions, mapGetters } from "vuex";

import BannerContainer from "./BannerContainer.vue";
import CargarBannerModal from "./CargarBannerModal.vue";
import Spinner from "../Spinner.vue";
export default {
  components: { BannerContainer, CargarBannerModal, Spinner },
  data() {
    return {
      forBannerH: this.$route.name === "CanalCorporativo-listadoBannersHorizontal",
      id: this.$route.params.id,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      show_spinner: false,
      active_button: false,
      counter: 4,
      active_time: false,
      add_modal: false,
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.onPageChange,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",        
      },
      banners: [],
      title: "",
      selected_banners: [],
      filesPerPage: "12"
    };
  },
  
  computed: {
    ...mapState("tvCorporativaModule", [
      "channelLayout", 
      "repoBannersPagination",
      "repoBannersHPagination"
    ]),
    ...mapGetters("tvCorporativaModule", [
      "repoBannersFiltered",
      "repoBannersHFiltered"
    ]),

    bannersPagination() {
      if (this.forBannerH) {
        return this.repoBannersHPagination;
      } else {
        return this.repoBannersPagination;
      }
    }
  },

  async mounted() {
    this.show_spinner = true;
    await this.getAllBannersFromRepo();
    await this.getPaginatedBanners();
    this.setCounterFromState();
    this.show_spinner = false;
  },

  methods: {
    ...mapActions("tvCorporativaModule", 
      ["setChannelLayoutBanners", "getChannelStateFromLS", "getFilesPagination", "getAllRepoFiles"]),

    setCounterFromState() {
      this.getChannelStateFromLS();
      this.counter = this.channelLayout.timePerBanner;
    },

    onPlaylistChange(selected_banners) {
      this.selected_banners = selected_banners;
      if (selected_banners.length >= 2) {
        this.active_time = true;
        this.active_button = true;
        return;
      }
      if (selected_banners.length == 1) {
        this.active_time = false;
        this.active_button = true;
        return;
      }
      if (selected_banners.length == 0) {
        this.active_time = false;
        this.active_button = false;
        return;
      }
    },

    addTime() {
      this.counter++;
    },

    removeTime() {
      if (this.counter > 4) {
        this.counter--;
      }
    },

    closeModal() {
      this.add_modal = false;
    },

    addBannerModal() {
      this.add_modal = true;
    },
    
    addBannerListToChannel() {
      const payload = {
        banners: this.selected_banners,
        timePerBanner: this.counter,
        isBannerH: this.forBannerH
      }
      this.setChannelLayoutBanners(payload);
      this.toastSetBannerPlaylistSuccess();
      this.$router.push({
        name: "CanalCorporativo-addContenido",
        params: { id: this.$route.params.id },
      });
    },

    onPageChange(page) {
      this.pagination.actual_page = page;
      this.getRepoFilesForPage(page);
    },

    async getAllBannersFromRepo() {
      const data = {
        idEmpresa: this.id_empresa,
        limite: this.filesPerPage,
        tipoArchivo: this.forBannerH ? "5" : "2"
      };
      const res = await this.getAllRepoFiles(data);
      if (!res) {
        this.toastGetBannersError();
      }
    },

    async getPaginatedBanners(nombre_banner="") {
      this.show_spinner = true;
      const data = {
        idEmpresa: this.id_empresa,
        tipoArchivo: this.forBannerH ? "5" : "2",
        limite: this.filesPerPage,
        nombreArchivo: nombre_banner
      }
      await this.getFilesPagination(data);
      if (this.bannersPagination === null) {
        this.toastGetBannersError();
      } else {
        if (this.bannersPagination.length > 0) {
          this.pagination.page_count = this.bannersPagination.length;
          this.pagination.actual_page = 1;
          this.getRepoFilesForPage(1);
        }
        if (this.bannersPagination.length == 0) {
          this.pagination.page_count = 1;
          this.pagination.actual_page = 1;
          this.banners = [];
        }
      }
      this.show_spinner = false;
    },

    getRepoFilesForPage(page) {
      this.banners = this.forBannerH ? 
        this.repoBannersHFiltered(page-1) : this.repoBannersFiltered(page-1);
    },

    async onBannerUpload() {
      await this.getAllBannersFromRepo();
      await this.getPaginatedBanners();
    },

    async onBannerListChange() {
      await this.getPaginatedBanners();
      this.pagination.actual_page = 1;
    },

    onBannerSearch() {
      this.getPaginatedBanners(this.title);
    },

    toastSetBannerPlaylistSuccess() {
      this.$toast.open({
        message: "Tu lista de banners ha sido agregada correctamente",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },

    toastGetBannersError() {
      this.$toast.open({
        message: "Error en la obtención de datos. Por favor intenta nuevamente recargando la página.",
        type: "error",
        duration: 6000,
        position: "top-right",
      });
    }

  },
};
</script>

