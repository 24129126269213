<template>
  <section id="form-pantalla">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <template v-if="add_view">
            <strong>Añadir pantalla:</strong> Ingresa los datos informativos de la pantalla.
          </template>
          <template v-else>
            <strong>Editar pantalla:</strong> Edita los datos informativos de la pantalla.
          </template>
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div
            class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0"
            :class="{ 'opacity-50': !add_view }"
          >
            <h5 class="font-main text-secondary">Validación pantalla</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Código *</label>
                    <input
                      name="codigo"
                      type="text"
                      class="form-control input-custom"
                      placeholder="Ingresa el nombre de tu canal"
                      maxlength="150"
                      v-model="code_key"
                      :disabled="add_view == false"
                      :class="{ 'is-invalid': nameReq['codigo'] }"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-end">
                    <button
                      v-show="show_button_key == true"
                      class="btn btn-custom-color-blue border-round-50 mw-100"
                      :disabled="button_key == false"
                      @click="validateKey"
                    >
                      Validar
                    </button>
                  </div>
                </div>
                <div v-if="add_view" class="row">
                  <div class="col-12">
                    <div
                      v-show="show_button_key == false"
                      class="alert alert-success text-center"
                    >
                      Pantalla validada exitosamente
                    </div>
                  </div>
                  <Spinner v-if="show_spinner" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0"
            :class="{ 'opacity-50': !active_containers }"
          >
            <h5 class="font-main text-secondary">Nombre de la pantalla</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Nombre *</label>
                    <input
                      name="nombre"
                      type="text"
                      class="form-control input-custom"
                      placeholder="Ingresa el nombre de la pantalla"
                      maxlength="150"
                      v-model="screen_name"
                      :disabled="active_containers == false"
                      :class="{ 'is-invalid': nameReq['nombre'] }"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Descripción</label>
                    <textarea
                      class="form-control textarea-custom"
                      placeholder="Ingresa una breve descripción de la pantalla"
                      maxlength="75"
                      rows="3"
                      v-model="screen_description"
                      :disabled="active_containers == false"
                    >
                    </textarea>
                    <div class="form-text text-end">
                      {{ screen_description.length }}/75
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0"
            :class="{ 'opacity-50': !active_containers }"
          >
            <h5 class="font-main text-secondary">Datos técnicos</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Ubicación *</label>
                    <input
                      name="ubicacion"
                      type="text"
                      class="form-control input-custom"
                      v-model="screen_location"
                      placeholder="Ingresa la ubicación exacta de la pantalla"
                      :class="{ 'is-invalid': nameReq['ubicacion'] }"
                      :disabled="active_containers == false"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Asignar canal *</label>
                    <v-select
                      name="screen_channel"
                      :class="{ 'is-invalid': nameReq['screen_channel'] }"
                      class="selvue-custom mb-3 border rounded-1 transition__border-color"
                      placeholder="Seleccionar un canal"
                      :clearable="false"
                      :options="listCanales"
                      :reduce="(canal) => canal.id_canal"
                      label="nombre_canal"
                      v-model="screen_channel"
                      :disabled="active_containers == false"
                    >
                      <span slot="no-options"></span>
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Zona horaria *</label>
                    <v-select
                      :class="{ 'is-invalid': nameReq['screen_timezone'] }"
                      class="selvue-custom mb-3 border rounded-1 transition__border-color"
                      name="screen_timezone"
                      placeholder="Seleccionar una zona"
                      :options="GetTimezones"
                      :reduce="(zona) => zona.zona_horaria"
                      label="zona_horaria"
                      v-model="screen_timezone"
                      :disabled="active_containers == false"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2"
            >
              <router-link
                v-show="valid_code == false"
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2"
                :to="{ name: 'CanalCorporativo-pantallas' }"
              >
                Volver
              </router-link>
              <button
                v-show="valid_code == true"
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2"
                @click="rejectScreen()"
              >
                Volver
              </button>
              <span
                content="Debes completar los campos <br> obligatorios (*), asegura que <br> nombre y ubicación tengan <br>al menos 5 caracteres."
                v-tippy="{
                  placement: 'top',
                  arrow: true,
                  trigger: active_button == false ? 'mouseenter focus' : '',
                }"
                v-on:mouseover="highlight()"
                v-on:mouseleave="cleanHighlight()"
              >
                <button
                  v-show="add_view == true"
                  class="btn btn-custom-color-blue border-round-50 mw-100"
                  @click="addScreen()"
                  :disabled="active_button == false"
                >
                  Añadir
                </button>
              </span>
              <span
                content="Debes completar los campos <br> obligatorios (*), asegura <br> que nombre y ubicación tenga <br>al menos 5 caracteres."
                v-tippy="{
                  placement: 'top',
                  arrow: true,
                  trigger: active_button == false ? 'mouseenter focus' : '',
                }"
                v-on:mouseover="highlight()"
                v-on:mouseleave="cleanHighlight()"
              >
              <button
                v-show="add_view == false"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="editScreen()"
                :disabled="active_button == false"
              >
                Editar
              </button>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {
  props: {
    id: String,
  },
  components: { Spinner },
  data() {
    return {
      code_key: "",
      valid_code: false,
      show_spinner: false,
      show_spinner_large: false,
      button_key: false,
      show_button_key: true,
      active_containers: false,
      screen_name: "",
      screen_description: "",
      screen_location: "",
      screen_channel: "",
      screen_timezone: "",
      active_button: false,
      add_view: true,
      codeValidationResp: null,
      nameReq: {
        codigo: false,
        nombre: false,
        ubicacion: false,
        screen_channel: false,
        screen_timezone: false,
      },
    };
  },

  mounted() {
    this.getOptions();
    const id = this.$route.params.id;

    if (id) {
      this.findScreen(Number(id));
      this.add_view = false;
      this.active_containers = true;
      this.show_button_key = false;
    }
  },

  watch: {
    code_key() {
      if (this.code_key.length === 6) {
        this.button_key = true;
      } else {
        this.button_key = false;
      }
    },

    screenConfig() {
      if (
        this.screen_name.trim().length > 4 &&
        this.screen_description &&
        this.screen_location.trim().length > 4 &&
        this.screen_channel &&
        this.screen_timezone
      ) {
        this.active_button = true;
      } else {
        this.active_button = false;
      }
    },
  },
  computed: {
    ...mapState("canalCorporativoModule", [
      "listCanales",
      "GetTimezones",
      "newSreen",
    ]),
    ...mapGetters("canalCorporativoModule", ["getScreenById"]),

    screenConfig() {
      const {
        screen_name,
        screen_description,
        screen_location,
        screen_channel,
        screen_timezone,
      } = this;
      return {
        screen_name,
        screen_description,
        screen_location,
        screen_channel,
        screen_timezone,
      };
    },
  },

  methods: {
    ...mapActions("canalCorporativoModule", [
      "ValidateScreenDispAction",
      "SaveScreenAction",
      "UpdateScreenAction",
      "GetOnePantallaAction",
      "GetListCanalesAction",
      "GetTimezonesActions",
      "RejectScreenSaving",
    ]),

    highlight() {
      const valueEls = ["INPUT", "SELECT", "TEXTAREA"];
      Object.keys(this.nameReq).forEach((name) => {
        const elemnt = document.querySelector(`[name="${name}"]`);
        if(!elemnt) return;
        if (valueEls.includes(elemnt.tagName)) {
          if (elemnt.value === "") {
            this.nameReq[name] = true;
          } else {
            this.nameReq[name] = false;
          }
        } else {
          if (this[name]==="") {
            this.nameReq[name] = true;
          } else {
            this.nameReq[name] = false;
          }
          
        }
      });
    },
    cleanHighlight() {
      Object.keys(this.nameReq).forEach((name) => (this.nameReq[name] = false));
    },

    async rejectScreen() {
      const data_ = {
        codigo: this.code_key,
        id_estado_dispositivo: 4, // Rechazado
      };
      const resReject = await this.RejectScreenSaving(data_);
      if (!resReject) {
        this.$toast.open({
          message:
            "Problemas al cargar información. Por favor contactar a soporte.",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
        return;
      }

      if (resReject.id_dispositivo) {
        this.$toast.open({
          message: "Los cambio no han sido guardados.",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
        this.$router.push({ name: "CanalCorporativo-pantallas" });
      } else {
        this.$toast.open({
          message: "No se puede cancelar el proceso.",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      }
    },
    async validateKey() {
      this.show_spinner = true;
      const data_ = {
        codigo: this.code_key,
      };
      this.codeValidationResp = await this.ValidateScreenDispAction(data_);
      if (!this.codeValidationResp) {
        this.valid_code = false;
        this.show_button_key = true;
        this.active_containers = false;
        this.$toast.open({
          message:
            "Problemas al cargar información. Por favor contactar a soporte.",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
        this.show_spinner = false;
        return;
      }

      if (this.codeValidationResp.id_dispositivo == null) {
        this.valid_code = false;
        this.show_button_key = true;
        this.active_containers = false;
        this.$toast.open({
          message: "El dispositivo no existe en nuestros registros.",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      } else if (this.codeValidationResp.id_dispositivo < 1) {
        this.valid_code = false;
        this.show_button_key = true;
        this.active_containers = false;
        this.$toast.open({
          message: "El dispositivo ya se encuentra en uso.",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      } else {
        this.screen_name = this.codeValidationResp.nombre_dispositivo;
        this.valid_code = true;
        this.show_button_key = false;
        this.active_containers = true;
      }
      this.show_spinner = false;
    },
    async getOptions() {
      const data_ = { id_estado: 1 };
      const result = await this.GetListCanalesAction(data_);

      let zones = await this.GetTimezonesActions();
      if (result == null || zones == null) {
        this.$toast.open({
          message:
            "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      }
    },
    async findScreen(id) {
      const data_ = {
        id_pantalla: id,
      };
      const result = await this.GetOnePantallaAction(data_);

      if (result == null) {
        this.$toast.open({
          message:
            "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      } else {
        if (result.id_pantalla != null) {
          this.screen_description = this.newSreen.descripcion_pantalla;
          this.screen_channel = this.newSreen.canal[0].id_canal;
          this.screen_timezone = this.newSreen.zona_horaria;
          this.code_key = this.newSreen.codigo;
          this.screen_name = this.newSreen.nombre_pantalla;
          this.screen_location = this.newSreen.ubicacion;
        } else {
          this.$toast.open({
            message: "La pantalla no existe",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
        }
      }

      // return result;
    },
    async addScreen() {
      this.show_spinner_large = true;
      let data_ = {
        codigo: this.code_key,
        nombre_pantalla: this.screen_name.trim(),
        descripcion_pantalla: this.screen_description,
        ubicacion: this.screen_location.trim(),
        id_canal: [+this.screen_channel],
        id_dispositivo: +this.codeValidationResp.id_dispositivo,
        zona_horaria: this.screen_timezone,
      };
      const resSave = await this.SaveScreenAction(data_);
      if (resSave != null) {
        if (resSave.id_pantalla != null) {
          this.$toast.open({
            message: "Tu pantalla ha sido agregada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$router.push({ name: "CanalCorporativo-pantallas" });
        } else {
          this.$toast.open({
            message: "Tu pantalla no se pudo registrar",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
        }
      } else {
        this.$toast.open({
          message:
            "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      }
      this.show_spinner_large = false;
    },

    async editScreen() {
      this.show_spinner_large = true;
      let data_ = {
        id_pantalla: this.$route.params.id,
        id_canal: [+this.screen_channel],
        codigo: this.code_key,
        nombre_pantalla: this.screen_name.trim(),
        descripcion_pantalla: this.screen_description,
        ubicacion: this.screen_location.trim(),
        zona_horaria: this.screen_timezone,
      };
      let resUpdate = await this.UpdateScreenAction(data_);
      if (resUpdate != null) {
        this.show_spinner_large = false;
        if (resUpdate.id_pantalla != null) {
          this.$toast.open({
            message: "Tu pantalla ha sido editada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$router.push({ name: "CanalCorporativo-pantallas" });
        } else {
          this.$toast.open({
            message: "Tu pantalla no se pudo editar",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
        }
      } else {
        this.show_spinner_large = false;
        this.$toast.open({
          message:
            "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      }
    },
  },
};
</script>
