import InicioAdmin from '@/views/ADMIN/Home.vue'
import InicioAdminDash from '@/components/HomeAdmin/Home.vue'

export const homeList = {
  path: "/inicio",
  name: "Inicio",
  component: InicioAdmin,
  redirect: { name: "inicio-dash" },
  children: [ {
      path: "dashboard",
      name: "inicio-dash",
      component: InicioAdminDash
    }
  ],
}
