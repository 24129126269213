import Gallery from '@/views/ADMIN/Gallery.vue'
import GalleryList from '@/components/Gallery/List.vue'
import GalleryAdd from '@/components/Gallery/Add.vue'

export const galleryList = {
  path: "/galeria",
  name: "Galeria",
  component: Gallery,
  redirect: { name: "gallery-list" },
  children: [
    {
      path: "list",
      name: "gallery-list",
      component: GalleryList
    },
    {
      path: "add",
      name: "gallery-add",
      component: GalleryAdd
    },
    {
      path: "edit/:id",
      name: "gallery-edit",
      component: GalleryAdd
    },
  ],
};