<template>

  <section id="gallery">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>
  
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          {{ subtitle }}
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/galeria/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear galería
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-7 input-search">
            <input
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar album" 
                v-model.trim="filter.search"
                ref="searchInput"
                @keypress.enter="getPaginadasGaleriaLista()"/>
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-9 col-xl-8">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GalleryList
      :data="galleries_list"
      :loading="loading"
      @openModal="openWatchAlbum"
      @downloadGallery="downloadAlbum"
      @editGallery="openEditAlbum"
      @deleteGallery="openDeleteAlbum"
      @statusGallery="changeStateGallery"
    />

    <SinResultados 
        msg="Aceptar" 
        custom-click 
        @click="nuevaBusqueda" 
      v-if="!loading && galleries_list.length === 0"/>

      <!-- Pregunta para eliminar album -->
      <Question
        v-if="open_question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelQuestion"
        @accept="deleteAlbum"
      />
      <!--  Modal que muestra el estatus de la accion (exito o error) -->
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />

      <ModalGallery
        :data="album_selected"
        @editGallery="openEditAlbum"
        @downloadGallery="descargarImagen"
      />

  </section>

</template>

<script>
  import Status from "../Modales/Status.vue";
  import JSZip from "jszip";
  import { saveAs } from "file-saver";

  import { mapState, mapActions } from "vuex";
  import moment from "moment";

  import SectionTabs from "../Section/SectionTabs.vue";
  import Question from "../Modales/Question.vue";
  import { downloadZip } from "client-zip";

  //FF
  import ModalGallery from "@/components/Gallery/ModalGallery.vue"
  import GalleryList from "@/components/Gallery/GalleryList.vue";
  import InputDate from "@/components/forms/InputDate.vue"
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: {
      Status,
      SectionTabs,
      Question,

      //FF
      GalleryList,
      InputDate,
      ModalGallery,
      SinResultados
    },

    data() {
      return {
        tab_component: [{
          name: "Galería",
          route: "gallery-list",
        }, {
          name: "Agregar",
          route: "gallery-add",
        }],
        subtitle:
          "Conoce todos los álbumes de fotos que están publicados en LinkiWork. Podrás descargarlos, editarlos o eliminarlos si lo requieres.",
        open_modal_photos: false,
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: false,
        galeria_selected_id: "",
        album_selected: {},
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        month_list: [],
        month_selected: { name: "Mes", id: "" },
        year_list: [],
        year_selected: "Año",
        open_select_list: "",
        galleries_list: [],
        search_text: "",
        no_results: "",
        id_usuario: this.$ls.get("user").id_usuario,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        order_list: [{
          amount: 1,
          size: 768,
        }, {
          amount: 2,
          size: 1280,
        }, {
          amount: 3,
          size: 1366,
        }, {
          amount: 4,
          size: 1440,
        }, {
          amount: 5,
          size: 1920,
          width: "",
        }],
        selected_order: {
          amount: 0,
          size: 0,
        },
        width_items: 200,
        open_question_modal: false,
        question_modal_msg: "",
        slide_idx: 1,
        date: new Date(),
        show_back_btn: false,
        open_full_img: false,
        url_selected: "",
        show_like: false,
        arrayDef: [],
        counterAlbum: 0,
        totalLengthAlbum: 0,
        pagina_actual: "1",
        pagination: {
          actual_page: 1,
          page_count: 1,
          page_range: 3,
          click_handler: this.paginacion,
          prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class:"pagination-item"
        },

        //FF
        loading: true,
        filter: { 
          search: ''
        },
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },

    watch: {
      album_list() {
        if (this.album_list && this.album_list.length > 0) {
          this.galleries_list = this.album_list;
          this.month_selected = { name: "Mes", id: "" };
          this.year_selected = "Año";
        }
      },

      "filter.search"(value) {
        if(value === '') {
          this.getPaginadasGaleriaLista();
        }
      },
      "range.date_start"() {
        this.getPaginadasGaleriaLista();
      },
      "range.date_end"() {
        this.getPaginadasGaleriaLista();  
      },
    },

    computed: {
      ...mapState("galleryModule", ["album_list", "sidebar_open", "paginas_galeria"]),

      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      }
    },

    created() {
      this.getPaginadasGaleriaLista();
    },
    methods: {
      ...mapActions("galleryModule", [
        "deleteGalleryAction",
        "getPaginasGaleria",
        "getGaleriaPaginasCompleto",
        "changeGalleryStateAction"
      ]),
      nuevaBusqueda(){
        this.filter.search = '';
        this.$refs.searchInput.focus();
        this.getPaginadasGaleriaLista();
      },
      //Paginador galerías
      paginacion(){
        let total = this.paginas_galeria.length;
        if(this.pagination.actual_page <= total){
          this.pagina_actual = this.pagination.actual_page;
          this.getPaginadasGaleriaLista();
        }
      },

      // Metodo que pagina y puebla la lista de galería para mostraren front
      async getPaginadasGaleriaLista() {
        this.loading = true;
        let data_ = {
          id_empresa: this.id_empresa,
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_final: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
          nombre: this.filter.search,
          limite: "10"
        };
        let pagina = this.pagina_actual;
        //console.log("data_ a enviar a nuevo método: ", {data_, pagina});
        await this.getGaleriaPaginasCompleto({data_, pagina});
        this.galleries_list = this.album_list;
        this.loading = false;
      },
      
      openWatchAlbum(album) {
        this.album_selected = album;
      },
      
      async deleteAlbum() {
        this.open_question_modal = false;
        this.loading = true;

        const payload = {
          id_empresa: this.id_empresa,
          id_galeria: this.galeria_selected_id,
          id_usuario: this.id_usuario,
        }
        const res = await this.deleteGalleryAction(payload);
        if (res) {
          this.loading = false;
          this.toastDeleteGallerySuccess();
          this.galleries_list = this.album_list;
        } else {
          this.loading = false;
          this.toastDeleteGalleryError();
        }
      },

      async changeStateGallery(gallery) {
        this.loading = true;
        const payload = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_usuario,
          id_galeria: gallery.id_galeria,
          id_estado: gallery.id_estado_fk == "1" ? "3" : "1",
          alerta_push: gallery.alerta_push ?? "0"
        };
        const resChangeState = await this.changeGalleryStateAction(payload);
        if (resChangeState) {
          this.toastStatusChangeSuccess();
        } else {
          this.toastStatusChangeError();
        }
        this.loading = false;
      },


      // Funcion para abrir modal de eliminar album
      openDeleteAlbum(id) {
        this.open_question_modal = true;
        this.question_modal_msg = "¿Realmente deseas eliminar este álbum?";
        this.galeria_selected_id = id;
      },

      // Funcion para editar un album
      openEditAlbum(album) {
        this.$router
          .push({
            name: "gallery-edit",
            params: { id: album },
          })
          .catch(() => {
            return;
          });
      },

      // Funcion para cerrar modal que muestra el estatus de la accion (exito o error)
      closeStatus() {
        this.open_modal_status = false;
      },
      
      // Buscar album por fecha
      async searchAlbumByDate() {
        console.log("searchAlbumByDate: por fecha inicio: ",this.init_date, " fecha de término: ",this.end_date);
        await this.getPaginadasGaleriaLista();
      },

      cancelQuestion() {
        this.open_question_modal = false;
      },

      descargarImagen(img, gallery) {
        let url = img.url_imagen_original;
        let nombre = gallery.titulo_galeria.replace(/\.|\s/g, "_");
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function() {
          if (xhr.status === 200) {
            var blob = xhr.response;
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${nombre}_${img.id_imagen}`;
            link.click();
          } else {
            console.log("Error downloading image: " + xhr.status);
          }
        };
        xhr.send();
      },
      // Descargar album
      async downloadAlbum(album) {
        // ref: https://stackoverflow.com/questions/69924072/jszip-wait-for-creation-of-zip-before-working-with-data
        // inspirado en https://arsfy.buzz/2022/02/20/Decode-webp-to-png-jpg-at-Browser-JavaScript/
        this.loading = true;
        let files = [];
        //extraigo un array de Blobs de google cloud 
        for (let i = 0; i < album.imagenes.length; i++) {
          // funcion busca el de blob y nombre de imagen de galeria + formato y genera un array con estos
          let bolb_content = await this.axios({
            url: album.imagenes[i].url_imagen_original,
            method: "GET",
            contentType: "application/x-www-form-urlencoded",
            responseType: "blob", // Important
          }).then((res) => {
            let blob = res.data;
            return {
              data: blob,
              name:
                album.titulo_galeria.replaceAll(/ /g, "_") +
                "_" +
                (i + 1) +
                ".png",
            };
          });
          files.push(bolb_content);
        }
        let filesPng = [];
        //Genera promesas de imagenes de cualquier formato parceadas a png
        const getFilesPngPromises = files.map((file) => {
          return new Promise((resolve, reject) => {
            var oFileReader = new FileReader();
            oFileReader.onloadend = function (e) {
              // Create a new Image Obj
              var newImg = new Image();
              // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
              newImg.crossOrigin = "Anonymous";
              newImg.onload = function () {
                // Create a new Canvas
                var canvas = document.createElement("canvas");
                // Set 2D context
                var context = canvas.getContext("2d");
                // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
                canvas.crossOrigin = "anonymous";
                // Set Width/Height
                canvas.width = newImg.width;
                canvas.height = newImg.height;
                // Start
                context.drawImage(newImg, 0, 0);
                // Get png Base64
                let imgSrc = canvas.toDataURL("image/png");
                resolve(filesPng.push(imgSrc.split(",")[1]));
              };
              newImg.onerror = (error) => {
                reject(error);
              };
              // Load Webp Base64
              newImg.src = e.target.result;
            };
            oFileReader.readAsDataURL(file.data);
          });
        });
        await Promise.all(getFilesPngPromises);
        // funcion que genera el zip final
        let uploadDocuments = async (files) => {
          //array de base64s
          const fileInfos = filesPng;
          let zip = new JSZip();
          //pushea los archivos base 64 y sus nombres al objeto zip
          for (let i = 0; i < files.length; i++) {
            zip.file(files[i].name, fileInfos[i], { base64: true });
          }
          // genera zip
          zip.generateAsync({ type: "blob" }).then(function (content) {
            // see FileSaver.js
            saveAs(content, album.titulo_galeria + ".zip");
          });
          this.loading = false;
          this.$toast.open({
            message: 'Álbum descargado correctamente.',
            type: 'success',
            duration: 5000,
            position: 'top-right'
          })
          // Profit
          return fileInfos;
        };
        uploadDocuments(files);
      },

      // Descarga ALBUM 2
      async loadImagesDownload(imagen) {
        var fileName = imagen.split(/(\\|\/)/g).pop();
        var image = new Image();

        image.crossOrigin = "anonymous";
        image.src = imagen;
        let self = this;
        image.onload = function () {
          // use canvas to load image
          var canvas = document.createElement("canvas");
          // canvas.width = this.naturalWidth;
          // canvas.height = this.naturalHeight;
          canvas.getContext("2d").drawImage(this, 0, 0);

          // grab the blob url
          var blob;
          if (image.src.indexOf(".jpg") > -1) {
            blob = canvas.toDataURL("image/jpeg");
          } else if (image.src.indexOf(".png") > -1) {
            blob = canvas.toDataURL("image/png");
          } else if (image.src.indexOf(".gif") > -1) {
            blob = canvas.toDataURL("image/gif");
          } else {
            blob = canvas.toDataURL("image/png");
          }
          self.getInfoBlob(blob, fileName);
        };
        // console.log(arrayDef);
      },
      // Descargan ALBUM 3
      async getInfoBlob(blob, fileName) {
        const code = await fetch(blob);
        var data = {
          input: code.url,
          name: fileName,
        };
        this.arrayDef.push(data);
        // console.log(this.arrayDef);
        this.counterAlbum++;
        if (this.counterAlbum == this.totalLengthAlbum) {
          this.downloadDef();
        }
      },
      // Descargar ALBUM 4
      async downloadDef() {
        console.log("downloadDef", this.arrayDef);
        var arrayZip = this.arrayDef;
        const blob = await downloadZip(arrayZip).blob();

        // make and click a temporary link to download the Blob
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.nombreGaleria;
        link.click();
        link.remove();
        this.arrayDef = [];
      },
      handleCalendar() {
        this.show_calendar = false;
      },

      toastDeleteGallerySuccess() {
        this.$toast.open({
          message: 'Álbum eliminado correctamente.',
          type: 'success',
          duration: 5000,
          position: 'top-right'
        });
      },
      toastDeleteGalleryError() {
        this.$toast.open({
          message: 'Ocurrió un error al eliminar el álbum. Por favor inténtalo nuevamente.',
          type: 'error',
          duration: 8000,
          position: 'top-right'
        });
      },
      toastGetGalleriesError() {
        this.$toast.open({
          message: 'Ocurrió un error al intentar obtener las galerías. Por favor inténtalo nuevamente.',
          type: 'error',
          duration: 8000,
          position: 'top-right'
        });
      },

      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      }
    },
  };
</script>
