<template>
  <div class="col-12 col-xl-4 d-flex flex-column mt-3 mt-lg-0">
    <h5 class="font-main text-secondary">Archivos</h5>
    <div class="card flex-grow-1">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-12">
            <label class="text-secondary"
              >Carga la imagen principal del video</label
            >
            <UploadImg @sendImg="handleMainImg" v-if="img_preview == ''" />
            <div v-if="img_preview !== ''" class="upload-image">
              <div class="upload-image-preview">
                <button
                  @click="deletePreview()"
                  class="upload-image-preview-remove"
                >
                  <font-awesome-icon icon="times" />
                </button>
                <img class="upload-image-preview-img" :src="img_preview" />
              </div>
            </div>
          </div>
        </div>
        <template v-if="!edit_video">
          <div class="row mb-3">
            <div class="col-12">
              <label class="text-secondary">Carga el video</label>
              <div class="input-group custom-file-button">
                <input
                  type="file"
                  class="form-control"
                  id="inputFileVideo"
                  @change="loadFiles($event)"
                  accept=".mp4 , .avi, .mov"
                />
                <label class="input-group-text" for="inputFileVideo">
                  Elegir
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="alert alert-light border text-center">
                <small
                  >Formato MP4 - Dimensiones 1280x720 - Peso máximo de
                  500MB</small
                >
              </div>
            </div>
          </div>
        </template>
        <template v-if="edit_video">
          <div class="row">
            <div class="col-12 text-end">
              <div
                v-if="
                  editing &&
                  video.name == 'Selecciona un video desde tus archivos'
                "
                class="btn btn-custom-color-white border border-round-50 mw-100"
                data-bs-toggle="modal"
                data-bs-target="#modal-preview-video"
                @click="openModalPreview()"
              >
                <font-awesome-icon icon="video" class="pe-1" />
                Vista previa video
              </div>
              <button @click="toggleEditVideo" class="btn btn-custom-color-white ms-2 border border-round-50 mw-100">
                Cambiar video
              </button>
          
            </div>
          </div>
        </template>


        <!-- Modal -->
        <div
          class="modal modal-custom fade"
          id="modal-preview-video"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5">
                  <font-awesome-icon icon="video" class="color-main pe-2" />
                  <span> Video </span>
                </h1>
                <button
                  type="button"
                  class="modal-custom-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <font-awesome-icon icon="xmark" />
                </button>
              </div>
              <div class="modal-body text-center">
                <video
                  controls
                  class="ratio ratio-16x9"
                  :src="video_preview"
                  autoplay
                ></video>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  ref="closeModal"
                  class="btn btn-custom-color-white rounded-pill mw-100"
                  data-bs-dismiss="modal"
                >
                  <i class="fa-solid fa-angle-left pe-1"></i>
                  Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoadingProgress :value="loading_progress" v-if="show_progress" />
  </div>
</template>

<script>
import UploadImg from "../UploadImg.vue";
import LoadingProgress from "../LoadingProgress.vue";

export default {
  components: {
    UploadImg,
    LoadingProgress,
  },
  props: {
    sec_dos: {
      type: Object,
    },
    editing: Boolean,
  },
  data() {
    return {
      video_url: "",
      video: {
        name: "Selecciona un video desde tus archivos",
        src: "",
        base64: "",
        type: "",
      },
      news_main_img: {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      },
      file_img: null,
      open_modal_preview: false,
      show_progress: false,
      loading_progress: 0,
      video_preview: "",
      img_preview: "",
      edit_video: false,
    };
  },
  watch: {
    sec_dos(sec_dos) {
      if (this.editing) {
        this.img_preview = sec_dos.img;
      }
    },
    file_img(fil) {
      if (fil !== null) {
        if (fil == undefined) {
          setTimeout(() => {
            this.file(fil);
          }, 200);
        }
        if (fil !== undefined) {
          this.file(fil);
        }
      }
    },
  },
  mounted() {
    console.log(this.sec_dos);
    this.checkEditing();
  },
  methods: {
    checkEditing() {
      console.log('Editing:', this.editing);
      console.log('Edit Video:', this.edit_video);
      console.log('Video URL:', this.sec_dos.video);
      if (this.editing) {
        this.video_url = this.sec_dos.video
        this.edit_video = true;
      }
    },
    toggleEditVideo() {
      this.edit_video = !this.edit_video;
    },
    openModalPreview() {
      //this.open_modal_preview = true;
      this.video_preview = this.sec_dos.video;
    },
    closeModalPreview() {
      this.open_modal_preview = false;
      this.video_preview = "";
    },
    file(file) {
      fetch(file)
        .then((res) => res.blob())
        .then((blob_file) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          let currentImg = "";
          let webpImg = "";
          let convertedImg = "";
          //Deja en 0 las variables si están siendo utilizadas
          if (currentImg != "" || webpImg != "" || convertedImg != "") {
            URL.revokeObjectURL(currentImg);
            convertedImg = "";
            currentImg = "";
            webpImg = "";
          }
          currentImg = URL.createObjectURL(blob_file);
          webpImg = new Image();
          webpImg.onload = () => {
            canvas.width = webpImg.naturalWidth;
            canvas.height = webpImg.naturalHeight;
            ctx.drawImage(webpImg, 0, 0, canvas.width, canvas.height);
            convertedImg = canvas.toDataURL("image/webp", 1.0);
            this.sec_dos.img = convertedImg;
          };
          webpImg.src = currentImg;
        });
    },
    //selectVideo
    loadFiles(eve) {
      var file = eve.target;
      var type = file.files[0].type.split("/")[0];
      var file_name = file.files[0].name;
      this.video.name = file_name;
      var rea = new FileReader();
      if (file.files) {
        switch (type) {
          case "video":
            if (file.files[0].size < 500 * 1024 * 1024) {
              rea.onloadstart = () => {
                this.show_progress = true;
              };

              rea.onload = (e) => {
                //console.log(e.target.result);
                this.video.src = e.target.result.split(",")[1];
                this.video.type = e.target.result.split(";")[0].split(":")[1];
                this.video.base64 = e.target.result;
                this.sec_dos.video = e.target.result;
              };

              rea.onprogress = (data) => {
                if (data.lengthComputable) {
                  this.loading_progress = parseInt(
                    (data.loaded / data.total) * 100,
                    10
                  );
                }
              };

              rea.onloadend = () => {
                this.show_progress = false;
              };

              rea.readAsDataURL(file.files[0]);
            } else {
              alert("El video excede el tamaño máximo de 500 MB");
            }
            break;
        }
      }
    },
    //select imagen
    handleMainImg(img) {
      this.news_main_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
        all: img.all,
      };
      this.file_img = img.all;
      // console.log(this.post_img);
    },
    deletePreview() {
      this.news_main_img = {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      };
      this.img_preview = "";
      this.file_img = null;
      this.sec_dos.img = null;
    },
  },
};
</script>
