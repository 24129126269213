import TransporteMain from '@/views/ADMIN/Transporte.vue'
import TransporteDash from '@/components/Transporte/Dashboard.vue'
import TransporteTramos from '@/components/Transporte/MisTramos.vue'
import Planificaciones from '@/components/Transporte/MisPlanificaciones.vue'
import EnCurso from '@/components/Transporte/EnCurso.vue'
import Transportes from '@/components/Transporte/Transportes.vue'
import InscripcionesPersonal from '@/components/Transporte/InscripcionesPersonal.vue'
import InscripcionesBusqueda from '@/components/Transporte/InscripcionesBusqueda.vue'
import InscripcionesAsignar from '@/components/Transporte/InscripcionesAsignar.vue'

export const transporteList = {
  path: "/transporte",
  name: "Transporte",
  component: TransporteMain,
  redirect: { name: "transporte-dashboard" },
  children: [ {
      path: "dashboard",
      name: "transporte-dashboard",
      component: TransporteDash
    }, {
      path: "tramos",
      name: "transporte-tramos",
      component: TransporteTramos
    }, {
      path: "planificaciones",
      name: "transporte-planificaciones",
      component: Planificaciones
    }, {
      path: "en_curso",
      name: "transporte-en_curso",
      component: EnCurso
    }, {
      path: "transportes",
      name: "transporte-transportes",
      component: Transportes
    }, {
      path: "inscripciones",
      name: "transporte-inscripciones",
      component: InscripcionesPersonal,
      children: [ {
          path: "list",
          name: "transporte-inscripciones-list",
          component: InscripcionesBusqueda
        }, {
          path: "add/:id",
          name: "transporte-inscripciones-add",
          component: InscripcionesAsignar
        }, {
          path: "edit/:id",
          name: "transporte-inscripciones-edit",
          component: InscripcionesAsignar
        },
      ],
    },
  ],
};