import Wall from '@/views/ADMIN/Wall.vue'
import WallList from '@/components/Wall/List.vue'
import WallProfiles from '@/components/Wall/ListPerfil.vue'
import WallAdd from '@/components/Wall/Add.vue'

export const wallList = {
    path: "/admin-muro",
    name: "Muro-admin",
    component: Wall,
    redirect: { name: "wall-list" },
    children: [{
            path: "list",
            name: "wall-list",
            component: WallList
        }, {
            path: "perfiles",
            name: "wall-perfiles",
            component: WallProfiles
        }, {
            path: "edit/:id",
            name: "post-edit",
            props: true,
            component: WallAdd
        }, {
            path: "add",
            name: "post-add",
            component: WallAdd
        },
    ],
};