    import Hotels from '@/views/ADMIN/Hotels.vue'
    import HotelsDashboard from '@/components/Hotels/Dashboard.vue'
    import HotelRequest from '@/components/Hotels/HotelRequest.vue';
    import HotelConsult from '@/components/Hotels/HotelConsult.vue';

    export const hotelsList = {
        path: "/hoteleria",
        name: "Hoteleria",
        component: Hotels,
        redirect: { name: "hotels-Dashboard" },
        children: [{
                path: "Dashboard",
                name: "hotels-Dashboard",
                component: HotelsDashboard
            },
            {
                path: "Solicitudes",
                name: "hotels-request",
                component: HotelRequest
            },
            {
                path: "Consultas",
                name: "hotels-consult",
                component: HotelConsult
            }
        ],
    };