import Encuestas from '@/views/ADMIN/Encuestas.vue'
import EncuestasList from '@/components/Encuestas/List.vue'
import EncuestasAdd from '@/components/Encuestas/Add.vue'

export const encuestasList = {
  path: "/encuestas",
  name: "Encuestas",
  component: Encuestas,
  redirect: { name: "encuestas-list" },
  children: [ {
      path: "list",
      name: "encuestas-list",
      component: EncuestasList
    }, {
      path: "add",
      name: "encuestas-add",
      component: EncuestasAdd
    }, {
      path: "edit/:id",
      name: "encuestas-edit",
      component: EncuestasAdd
    },
  ],
};